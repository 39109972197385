import React, { useEffect } from 'react';
import JSON5 from 'json5'
import sanitizeHtml from 'sanitize-html';

import { nodeName , nodeString} from './Renderer/Utils.tsx';
import parse, { domToReact } from 'html-react-parser';

import { Group } from './Renderer/ui/Views/Group.tsx';
import { isVariable, isVariableOrExpression, SetVariables, Variable, extractVariable,  Variables } from './Renderer/ui/Variable.tsx';
import { componentsMap, modifiersMap } from './YapUIDecoder.js';

import AST from './YapUIAST.js'

String.prototype.capitalize = function() {
    return this.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
};

/**
 * Encoding
 */
const modifiers = [
    'padding',
    'blur',
    'background',
    'border',
    'cornerRadius',
    'containerRelativeFrame',
    'frame',
    'font',
    'lineSpacing',
    'foregroundStyle',
    'flexFrame',
    'safeAreaPadding',
    'id'
];

const containsTextContent = [
    'Text', 'Button', 'UIFunction', 'Function', 'Paragraph'
]

const propRemap = {
    'maxwidth' : 'maxWidth',
    'maxheight' : 'maxHeight',
    'idealwidth' : 'idealWidth',
    'idealheight' : 'idealHeight'
}

const modifierValueMap = {
    'padding': 'length',
    'containerrelativeframe' : 'axes'
}

const containsNonWhitespace = (str) => {
    return /\S/.test(String(str));
}

/**
 * YapUIEncoder traverses and encodes a React component tree (JSX) into a JSON-like format.
 * It is customizable through `nameCallback` and `viewCallback` to manipulate component names and structure.
 *
 * @param {ReactElement} jsx - The root JSX element to be processed.
 * @param {Object} options - Object containing optional callback functions.
 * @param {function} [options.nameCallback] - A function that modifies the name of a component or HTML tag.
 *                                            The function takes a string and returns a modified version of it.
 *                                            If the function returns null or undefined, the original name is used.
 * @param {function} [options.viewCallback] - Currently not in use, reserved for future view management logic.
 *
 * @returns {Object} Encoded JSX as YapUI Directives or Variables.
 */
export function YapUIEncoder(jsx, { nameCallback, viewCallback }) {

    /**
     * Recursively encodes a React node into a YapUI Directive or Variable.
     *
     * @param {ReactElement} node - The React node to be encoded.
     * @param {boolean} hasTextContent - Indicates whether the node or its children contain textual content.
     * 
     * @returns {Object|null} Encoded node as YapUI Directive or Variable, or null if the node is invalid.
     */    
    const encode = (node, hasTextContent = false) => {
        // Base case: if the node is not a valid React element, return null
        if (!React.isValidElement(node)) {
            if (hasTextContent) {
                return node;
            } else {
                return null;
            }
        }

        // Get the type (component or HTML tag) and props
        const { type } = node;
        var props = {...node.props };
        
        // Prepare the node object
        let name = typeof type === 'string' ? type : type.displayName || type.name || type.displayName || 'Unknown'

        var args = {}
        var children = []

        for (const modifierName of modifiers) {
            if (modifierName.toLowerCase() == name.toLowerCase()) {
                name = modifierName
            }

            switch (name) {
                case 'Variable':
                    args['key'] = props['name'];
                    break;
            }
        }

        if (nameCallback) {
            let newName = nameCallback(name.toLowerCase())
            if (newName) {
                name = newName;
            }
        }

        // Add props to the JSON tree, excluding children
        for (const propName in props) {
            var propValue = props[propName];
            
            // Conver variable
            if (isVariable(propValue)) {
                propValue = AST.Variable(extractVariable(propValue));
            }

            if (propName !== 'children' && !modifiers.includes(propName)) {
                var newPropName = propRemap[propName] ?? propName
                args[newPropName] = propValue;
            }

            if (propName == 'value' && modifierValueMap[name.toLowerCase()] && modifiers.includes(name)) {
                var newPropName = modifierValueMap[name.toLowerCase()] ?? 'value'
                args[newPropName] = propValue;   
                delete args['value']
            }
        }

        const hasAnyReactNodes = props.children && React.Children.toArray(props.children).filter( (item) => { React.isValidElement(item) }) > 0;

        // Recursively traverse the children if they exist
        React.Children.forEach(props.children, (child) => {
            if (isVariable(child)) {
                children.push(AST.Variable(extractVariable(child)));
            } else {
                const childNode = encode(child, hasAnyReactNodes == false && containsNonWhitespace(child));// && containsTextContent.includes(name));
                if (childNode) {
                    children.push(childNode);
                }
            }
        });


        var jsonNode 
        switch (name) {
            case 'Variable':
                jsonNode = AST.Variable(args['name']);
                break;
            default:
                jsonNode = AST.Directive(name, args, children);
                break;
        }
        
        return jsonNode;
    }

    
    let jsxContent = jsx;
    
    // rewrite modifiers
    jsxContent = rewriteReactModifiers(jsxContent);

    return encode(jsxContent) 
}


/**
 * jsxStringToReact
 * 
 * Converts a JSX string to a React tree.
 * 
 * Any inbuilt components will be converted to their respective React components.
 * Any custom components will be wrapped in a component with the same name and flagged as unresolved. 
 * 
 * @param {} jsx 
 * @returns 
 */
export function jsxStringToReact(jsx) {

    const parseView = (name, content, attributes)  => {
        const BuiltInComponent = componentsMap[name.toLowerCase()];
        if (BuiltInComponent) {
            return <BuiltInComponent {...attributes}>{content}</BuiltInComponent>;
        } else { 
            let ComponentName = name
            return <ComponentName {...attributes}>{content}</ComponentName>;
        }
    };
 
    const options = {
        replace(node) {
            const { type, name, attribs, children } = node;

            if (!attribs) { return; }            

            const c = () => {
                return domToReact(children, options)
            }

            return parseView(name, c(), attribs)
        },
    }

    try {
        const sjsx = sanitizeHtml(jsx + "\n", {
            allowedTags: false,
            allowedAttributes: false,
            allowVulnerableTags: true
        })
        const result = parse(sjsx, options).filter( (item) => { return React.isValidElement(item) } )
        if (result.length > 1) {
            return <Group>{result}</Group>
        } else if (result.length) {
            return result[0]
        } else {
            return result
        }
    } catch (e) {
        console.log('error')
        console.log(e)
    }
}

/**
 * 
 * rewriteVariables
 *
 * Find ndoes with properties that have expressions or variables in them and wrap them in a SetVariables component that will execute the expression at runtime.
 * A node ends up with a single SetVariables component that wraps all the properties that have expressions or variables in them.
 * @param {} node 
 * @returns 
 */
export function rewriteVariables(node) {

    const ignoredProps = [
        'ontap',
        'onclick'
    ]

    const ignoredNodeNames = [
        'ontap',
        'onclick',
        'variable',
    ]

    const ignoredNodeContent = [
        'variable'
    ]

    // Early exit for ignore nodes
    const name = nodeName(node);
    const lcname = String(name).toLowerCase();
    
    if (ignoredNodeContent.includes(lcname)) {
        return node;
    }

    if (ignoredNodeNames.includes(lcname)) {
        const wrappedChildren = React.Children.map(node.props.children, rewriteVariables);
        return React.cloneElement(node, node.props, wrappedChildren);
    }

    if (!React.isValidElement(node)) {
        if (typeof node === 'string' && nodeName(node) != 'Variable') {
            let stringContent = nodeString(node);
            if (isVariableOrExpression(stringContent)) {
                return <Variable>{stringContent}</Variable>;
            }
        }
        return node;
    }


    let newProps = {};
    let shouldWrap = false;

    var variables = {}

    const propFilter = (propName) => { return ignoredProps.includes(String(propName).toLowerCase()) == false }

    Object.keys(node.props).filter(propFilter).forEach(propName => {
        const propValue = node.props[propName];

        if (isVariableOrExpression(propValue)) {
            shouldWrap = true
            variables[propName] = propValue;   
        } else {
            newProps[propName] = propValue;
        }
    });

    const wrappedChildren = React.Children.map(node.props.children, rewriteVariables);

    if (shouldWrap) {
        return (
            <SetVariables variables={variables}>
                {React.cloneElement(node, newProps, wrappedChildren)}
            </SetVariables>
        );
    } else {
        return React.cloneElement(node, newProps, wrappedChildren);
    }
}

export const resolveUnresolvedNodes = (node, resolveCallback, lastChildren) => {
    // If the node is a string or number, return it as is
    if (typeof node === 'string' || typeof node === 'number') {
        return node;
    }

    // If the node is an array, map over its children
    if (Array.isArray(node)) {
        return node.map(child => resolveUnresolvedNodes(child, resolveCallback, lastChildren));
    }

    // If the node is an object (React element)
    if (React.isValidElement(node)) {
        
        // If the node has _unresolved={true}, resolve it
        if (node.props && node.props._unresolved === true) {

            var resolvedProps = {...node.props};


            const resolvedNode = resolveCallback(node.type, resolvedProps, node.children);
            var returnNode = null


            // If the resolved node contains a <Children/> component, replace it with the original children
            if (resolvedNode && resolvedNode.props && resolvedNode.props.children) {
                returnNode = resolveUnresolvedNodes(resolvedNode, resolveCallback, node.props.children);
            } else {
                returnNode = resolvedNode;
            }

            var variableProps = {...resolvedProps};

            return (
                <Variables {...variableProps}>
                    {returnNode}
                </Variables>
            )
        }

        // If it's a regular node, clone it and recursively resolve its children
        const newProps = { ...node.props };
        if (newProps.children) {
            newProps.children = React.Children.map(newProps.children, child => {
                // if (child && (child.type === Children  || String(child.type).toLowerCase() === 'children')) {
                //     return resolveUnresolvedNodes(lastChildren, resolveCallback, null);
                // } else {
                    return resolveUnresolvedNodes(child, resolveCallback, lastChildren);
                //}
            });
        }

        return React.createElement(node.type, newProps);
    }

    // For any other type of node, return it unchanged
    return node;
}




/**
 * rewriteReactModifiers
 * 
 * Find nodes with properties that are in-built modifiers and wrap them in a component that will apply the modifier to the node.
 * 
 * It will also translate any properties that are prefixed with the modifier name to be a prop on the modifier component.
 * So for example:
 * frameMaxWidth={100} will become <Frame maxWidth={100}>...</Frame>
 * 
 * @param {} node 
 * @returns 
 */
function rewriteReactModifiers(node) {


    /**
     * Shortcuts
     */
    let propertyRemap = {
        'maxwidth' : 'framemaxwidth',
        'maxheight' : 'framemaxheight',
        'width' : 'framewidth',
        'height' : 'frameheight',
        'minwidth' : 'frameminwidth',
        'minheight' : 'frameminheight',
    }

    const modifiers = Object.keys(modifiersMap);


    const parseModifier = (name, content, attributeValue) => {   
        let Modifier = modifiersMap[name];
        if (Modifier) {  
            return <Modifier {...attributeValue}>{content}</Modifier>;
        } else {
            return null
        }
    }

    // Traverse the react node and if it has any property that's in the modifiers list, then wrap it in a modifier component as returned by parseModifier and remove that prop from the node 
    const traverseNode = (node) => {
        if (!React.isValidElement(node)) {
            return node;
        }

        let element = node;
        const props = { ...node.props };

        // Traverse children if they exist
        if (props.children) {
            props.children = React.Children.map(props.children, traverseNode);
        }

        var nodeProps = {...props}
        modifiers.forEach((modifier) => {
            const name = modifier.toLowerCase();

            // Get prefixed props
            for (var propName of Object.keys(props)) {  
                if (propName.startsWith(name) && propName !== name) {   
                    delete nodeProps[propName]; 
                }
            }

            delete nodeProps[name];
        });

        // Clone the element and set new props that don't include the modifiers
        let filteredElement = { ...element, props: nodeProps };
        element = React.cloneElement(filteredElement, { }, props.children);

        // Apply modifiers and make them the parent of the current node
        modifiers.forEach((modifier) => {
            var elementProps = {};

            const name = modifier.toLowerCase();


            // Get prefixed props.
            // So any props that have modifiernamePropertyname will become a prop on the modifier like 'propertyname' = 'value' 
            for (var propName of Object.keys(props)) {  
                const elementPropName = propertyRemap[propName] ?? propName  
                if (elementPropName.startsWith(name) && elementPropName !== name) {   
                    const keyName = elementPropName.replace(name, '')

                    // Add new props to the elementProps object
                    elementProps[keyName] = props[propName]
                }
            }   

            // Otherwise use the propertyName=value as the value
            if (props.hasOwnProperty(name)) {
                var value = props[name];
                elementProps['value'] = value;
                delete props[name];
            }

            // Wrap in the modifier if it has any props found for it.
            if (Object.keys(elementProps).length > 0) { 
                element = parseModifier(name, element, elementProps );
            }

        });

        return element;
    };

    return traverseNode(node);    
}   
