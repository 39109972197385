import React, { createContext, useContext, ReactNode } from 'react';
import { useStyle, StyleProvider, ForegroundStyleProvider } from './Style.tsx';
import styled from 'styled-components'
import JSON5 from 'json5'
import { IDContext } from './ID.tsx'
import { pt, px, pxnull, asNumber } from '../Utils.tsx'
import { ZStack } from './Layout.tsx';
import { Color, useColorNode } from './Views/Color.tsx';
import { Variables, SetVariables } from './Variable.tsx';

// Define a type for the Asset object
interface Padding {
    value?: string, 
    length?: string, 
    leading?: string, 
    trailing?: string, 
    top?: string, 
    bottom?: string, 
    horizontal? : string,
    children : React.ReactNode
}

export function Padding({ value, length, leading, trailing, top, bottom, horizontal, children } : Padding) {
    // Initialize the style object with the current style values
    var style = { ...useStyle() };

    const currentInsets = {
        left: asNumber(style['paddingLeft']),
        right: asNumber(style['paddingRight']),
        top:  asNumber(style['paddingTop']),
        bottom:  asNumber(style['paddingBottom'])
    }

    var insets = {
        left: 0,
        right: 0,
        top:  0,
        bottom: 0
    }

    // Apply the padding value to all sides if provided
    if (value) {
        insets = { left:  asNumber(value), right: asNumber(value), top: asNumber(value), bottom: asNumber(value) };
    }

    if (length) {
        insets = { left: asNumber(length), right: asNumber(length), top: asNumber(length), bottom: asNumber(length) };        
    }

    // Override specific sides if individual values are provided
    if (leading) {
        insets.left = asNumber(leading);
    }

    if (trailing) {
        insets.right = asNumber(trailing);  
    }

    if (horizontal) {
        insets.left = asNumber(horizontal);
        insets.right = asNumber(horizontal);
    }

    if (top) {
        insets.top = asNumber(top);     
    }
    
    if (bottom) {
        insets.bottom = asNumber(bottom);  
    }

    const finalInsets = {
        left: currentInsets.left + insets.left,
        right: currentInsets.right + insets.right,
        top: currentInsets.top + insets.top, 
        bottom: currentInsets.bottom + insets.bottom    
    }

    style.marginLeft  = finalInsets.left < 0 ? px(finalInsets.left) : 0;    
    style.paddingLeft = finalInsets.left > 0 ? px(finalInsets.left) : 0;    

    style.marginRight  = finalInsets.right < 0 ? px(finalInsets.right) : 0;    
    style.paddingRight = finalInsets.right > 0 ? px(finalInsets.right) : 0;    

    style.marginTop  = finalInsets.top < 0 ? px(finalInsets.top) : 0;    
    style.paddingTop = finalInsets.top > 0 ? px(finalInsets.top) : 0;    

    style.marginBottom  = finalInsets.bottom < 0 ? px(finalInsets.bottom) : 0;    
    style.paddingBottom = finalInsets.bottom > 0 ? px(finalInsets.bottom) : 0;    

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Border
 */
export function Border({ value, color, width, children } : { value?: string, color?: string, width?: string, children: React.ReactNode[] }) {
    var style = {...useStyle()};

    style.borderColor = color ?? value;
    style.borderStyle = 'solid';
    style.borderWidth = style.borderWidth ?? pxnull(width) ?? px("1");

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function BorderWidth({ value, children } : { value?: string, children: React.ReactNode[] }) {
    var style = {...useStyle()};

    if (value) {
        //style.borderColor = style.borderColor ?? 'black';
        //style.borderStyle = style.borderStyle ?? 'solid';
        style.borderWidth = px(value);
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * CornerRadius
 */
export function CornerRadius({ value, children } : { value?: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};

    if (value) {
        style.borderRadius = value ? px(value) : style.borderRadius;
        style.overflow = 'hidden';
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Blur
 */
export function Blur({ value, children }: { value?: string, children: React.ReactNode[] }) {
    const style = { ...useStyle() };

    if (value && parseInt(value) > 0) {
        style.filter = `blur(${px(value)})`;
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Background
 */
export function Background({ value, children }: { value?: string | React.ReactNode, children: React.ReactNode[] }) {
    const style = { ...useStyle() };
    
    // Check if the value is a React element of type 'Color'
    if (React.isValidElement(value)) {
        if (value.type === SetVariables) 
            return React.cloneElement(value, { props: { ...value.props, content: children }} as any);   
        if (value.type === Color) {
            return React.cloneElement(value, { ...value.props, content: children } as any);   
        } else {
            return (
                <ZStack>
                    {value}
                    {children}
                </ZStack>
            )
        }   
    }
    return children
} 

/**
 * Overlay
 */
export function Overlay({ value, children } : { value?: string, content, children: React.ReactNode[] }) {
    return (
            <ZStack>
                {value}                   
                {children}             
            </ZStack>
    )
}

/**
 * ForegroundStyle
 */
export function ForegroundStyle({ value, children } : { value?: string, children: React.ReactNode[] }) {
    const colorValue = useColorNode(value);
    const style = { color: colorValue };
    return <ForegroundStyleProvider style={style}>{children}</ForegroundStyleProvider>;
}

/**
 * Shadow
 */
export function Shadow({ value, radius, x, y, color, children } : { value?: string, radius: string, x: string, y: string, color: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};
    var shadowRadius = radius ?? "30"
    var shadowX = x ?? "0"
    var shadowY = y ??  String(parseInt(shadowRadius) / 3)
    var shadowColor = color ?? 'rgba(0, 0, 0, 0.1)'
    if (value) {
        shadowRadius = value;
    }

    style.boxShadow = `${px(shadowX)} ${px(shadowY)} ${px(shadowRadius)} ${shadowColor}`;

    return <StyleProvider style={style}>{children}</StyleProvider>;
}


/**
 * Frame
 */
export function Frame({ maxwidth, maxWidth, width, height, minwidth, minWidth, maxHeight, maxheight, minHeight, minheight, children, alignment}) {
    const style = {...useStyle()};

    // var minWidth = minwidth;
    // var minHeight = minheight;
    // var maxWidth = maxwidth;
    // var maxHeight = maxheight;

    if (maxWidth == 'infinity' || maxWidth == Infinity) {
        style.width = '-webkit-fill-available';
    } else if (maxWidth) {
        style['maxWidth'] = px(maxWidth);
    }

    if (minWidth) {
        style['minWidth'] = px(minWidth);
    }

    if (width) {
        style.width = px(width);
    }

    if (maxHeight == 'infinity' || maxHeight == Infinity) {
        style.height = '-webkit-fill-available';
    }  else if (maxHeight) {
        style['maxHeight'] = px(maxHeight);
    } 

    if (minHeight) {
        style['minHeight'] = px(minHeight);
    }

    if (height) {
        style.height = px(height);
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FlexFrame(props) {  
    return Frame(props);    
}

/**
 * ContainerRelativeFrame
 */
export function ContainerRelativeFrame({ value, children }) {
    return children
}

/**
 * ID
 */
export function ID({ value, children }) {
    return <IDContext.Provider value={{id: value}}>{children}</IDContext.Provider> 
}

/**
 * Disabled
 */
export function Disabled({ value, children }) {
    if (value == null || value == true) {
        return <Variables disabled={true}>{children}</Variables>
    } else {
        return children
    }
}

/**
 * Font
 */
export function Font({ value, children }) {
    const style = {...useStyle()};

    const fontStyle = {
        title: [28, '700'],          // Title, typically bold and larger
        largeTitle: [34, '900'],     // Large title, used for prominent headings
        title1: [28, '700'],         // Title 1, a bit larger
        title2: [22, '600'],         // Title 2, smaller than Title 1
        title3: [20, '600'],         // Title 3, smaller than Title 2
        headline: [17, '600'],       // Headline, typically bold text
        subheadline: [15, '400'],    // Subheadline, smaller than headline
        body: [17, '400'],           // Body, regular text size
        callout: [16, '400'],        // Callout, slightly smaller than body
        footnote: [13, '400'],       // Footnote, smaller text
        caption: [12, '400'],        // Caption, small, regular text
        caption2: [11, '400'],       // Caption 2, even smaller
    };

    const fontDefinition = fontStyle[value];  

    if (fontDefinition) {
        style.fontSize = px(fontDefinition[0]);
        style.fontWeight = style.fontWeight ?? fontDefinition[1];
    }
    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FontWeight({ value, children }) {
    const style = {...useStyle()};
    const fontWeightMap = {
        bold: '700',
        semibold: '600',
        medium: '500',
        regular: '400',
        light: '300',
        thin: '200',
        ultralight: '100',
        black: '900',
        heavy: '800'
    };

    var weightDefinition = fontWeightMap[value];   
    if (weightDefinition) {
        style.fontWeight = weightDefinition
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}   

export function LineSpacing({ value, children }) {
    const style = {...useStyle()};
    const ptValue = pt(value);
    if (ptValue) {
        style.lineHeight = ptValue;
    }
    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}   

/**
 * Hit Testing
 */
export function AllowsHitTesting({ value, children }) {
    const style = {...useStyle()};
    style.pointerEvents = value == 'true' ? 'auto' : 'none';
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function UnhandledModifer({ children }) {
    return children
}