import React, { useState, useRef } from 'react'

// Styling
import styled from 'styled-components'

import { fileUploader } from '../AppData.js'

import { 
        YapAssetLibraryAssets,
        YapAssetFileUploader,
        YapAssetLibraryAssetDetail,
        YapAssetType,
        YapToolbar,
        YapScrollable,
        YapProgressBar,
        YapHeaderButton
 } from '@yapstudios/yap-content-builder'

 import { v4 as uuidv4 } from 'uuid';

 /**
  * View Model
  * @param {*} param0 
  * @returns 
  */
export function useAssets({ assets, setAssets }) {    
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showUploadAsset, setShowUploadAsset] = useState(false);
    const [uploadingAsset, setUploadingAsset] = useState(false); 
    const [uploadProgress, setAssetUploadProgress] = useState(0)
    const [uploadProgressInfo, setAssetUploadProgressInfo] = useState({ index: 0, total: 0 })
    const [uploadingError, setUploadingError] = useState(null)

    const uploadCompleteCallback = useRef(null)
    const uploadCancelCallback = useRef(null)
    
    const uploadingAssetRef = React.useRef(null)
    uploadingAssetRef.current = uploadingAsset

    const assetsRef = React.useRef(null)
    assetsRef.current = assets
    
    const onComplete = useRef(null)

    const libraryTypes = [YapAssetType.Image, YapAssetType.Video]
    
    const cancelUpload = () => {
        if (uploadCancelCallback.current) {
            uploadCancelCallback.current()
        }
    }

    const onAssetImport = (file, asset, album, progressCallback, completeCallback, errorCallback) => {
        
        var asset = asset 
        asset.album = 'default'

        const uploadTask = fileUploader({
            file: file,
            asset: asset,
            progressCallback: progressCallback,
            completeCallback: (asset) => {
                console.log('completeCallback ', asset)
                assetsRef.current = [...assetsRef.current, asset]
                setAssets(assetsRef.current)
                completeCallback(asset)
            },
            errorCallback: errorCallback,
        })

        // Return value to onAssetImport is a function that will be called if the upload is cancelled by the user
        return () => {
            uploadTask.cancel()
        }
    }

    const onAssetDeleteAction = (asset) => {    
        setAssets(assets.filter((a) => a.id != asset.id))
        setSelectedAsset(null)
    }

    const progressCallback = (progress, progressInfo, uploading, error) => {
        if (uploadingAssetRef.current == false) {
            console.warn(`progressCallback called while no asset uploading. progress [${progress}]`);
            return;
        }

        let total = progressInfo.total
        let index = progressInfo.index

        let fileProgress = index / total
        let totalProgress = fileProgress + (progress / total)

        if (error) {
            setUploadingError(error)
            setUploadingAsset(false)
            setAssetUploadProgress(0)
        } else {
            if (uploading != null && uploading == false && index == total - 1) {
                setAssetUploadProgress(1.0)
                setUploadingAsset(false)       
                console.log('- upload complete')             
            } else {
                setAssetUploadProgress(totalProgress)
                setUploadingAsset(true)        
                console.log(`- upload progress [${totalProgress}]`);
            }
        }
    }

    return { assets, setAssets, 
            selectedAsset, setSelectedAsset, 
            showUploadAsset, setShowUploadAsset,
            uploadingAsset, setUploadingAsset ,
            uploadProgress, setAssetUploadProgress,
            uploadProgressInfo, setAssetUploadProgressInfo,
            uploadingError, setUploadingError,
            uploadCompleteCallback, uploadCancelCallback,
            progressCallback,
            cancelUpload,
            onComplete,
            onAssetImport,
            onAssetDeleteAction,
            libraryTypes
     }
}


export function Assets({ assets }) {
    
    var uploadStatusToolbar = null
    if (assets.uploadingAsset) {
        const uploadingStatus = 
            (<AssetsUploadInfo>
                    <p>Uploading {assets.uploadProgressInfo.index + 1} of {assets.uploadProgressInfo.total}</p>
                    <YapProgressBar progress={assets.uploadProgress} />
            </AssetsUploadInfo>
            );

        const uploadCanceAction = [
            <YapHeaderButton key="cancel" onClick={() => { assets.cancelUpload() }}>{"Cancel"}</YapHeaderButton>
        ]

        uploadStatusToolbar = <YapToolbar actionsLeft={uploadingStatus} actionsRight={uploadCanceAction}></YapToolbar>
    }

    return (
        <AssetsContainer>
            <YapScrollable>
                <AssetsContainerContent>
                    <YapAssetLibraryAssets assetLibraryTypes={assets.assetLibraryTypes} selectedAsset={assets.selectedAsset} assets={assets.assets ?? []} onAssetSelected={(asset) => {
                        asset != assets.selectedAsset ? assets.setSelectedAsset(asset) : assets.setSelectedAsset(null)
                    }} />
                </AssetsContainerContent>
            </YapScrollable> 
            {uploadStatusToolbar}
        </AssetsContainer>       
    )
}

export function AssetsDetail({ assets }) {
    const { selectedAsset, onAssetEdit, onAssetDeleteAction } = assets
    return (
        <YapScrollable>        
            <AssetsDetailContainer>
                    {selectedAsset && <YapAssetLibraryAssetDetail key={selectedAsset.assetVersion} onDelete={onAssetDeleteAction} onEdit={onAssetEdit} asset={selectedAsset} />}
            </AssetsDetailContainer>
        </YapScrollable>        
    )
}

export function AssetUploaderButton({ assets }) {
    return (
    <YapAssetFileUploader 
        canMultiUpload={true}
        types={assets.libraryTypes}
        withDimensions={true}
        onWillBeginUploading={() => {
            assets.setUploadingAsset(true)
        }}
        onError={() => {
            assets.uploadCancelCallback.current = null
            assets.setUploadingAsset(false)
        }}
        onUploadFile={(file, dimensions, isVideo, progressInfo, onComplete) => {
            var asset = { id: uuidv4(), title: removeFileExtension(file.name), created: new Date(), type: file.type, isVideo: isVideo, size: file.size, dimensions: dimensions }

            assets.setAssetUploadProgress(0)  
            assets.setAssetUploadProgressInfo(progressInfo)
            assets.setUploadingAsset(true)
            assets.setSelectedAsset(asset) 
            
            assets.uploadCompleteCallback.current = onComplete

            const currentUploadCancelCallback = assets.onAssetImport(file, asset, { }, 
            (progress, uploading, error) => {
                if (assets.progressCallback) {
                    assets.progressCallback(progress, progressInfo, uploading, error)
                }
            }, (asset) => {

                if (progressInfo.index == progressInfo.total - 1) {
                    assets.setUploadingAsset(false)
                    assets.setSelectedAsset(asset)
                }

                assets.setAssetUploadProgress(0)    
                onComplete(true)

            }, (error) => {
                assets.setUploadingAsset(false)
                assets.setAssetUploadProgress(0)     

                if (error) {
                    assets.setUploadingError(error)
                }

                onComplete(false)
            })
            
            assets.uploadCancelCallback.current = currentUploadCancelCallback
            
        }}>{"Add"}</YapAssetFileUploader>)
}


function removeFileExtension(filename) {
    var lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
        return filename.substring(0, lastDotIndex);
    }
    return filename;
}


const AssetsContainer = styled.div`
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    .polymer-toolbar-actions-left {
        flex-grow: 0.33;
    }
`

const AssetsUploadInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    width: 100%;
    p {
        font-weight: 500;
        white-space: nowrap;
    }
`

const AssetsContainerContent = styled.div`
    background-color: #f5f5f5;  
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    padding: 20px;
`

const AssetsDetailContainer = styled.div`
    background-color: white;  
    margin: 20px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;

    .asset-detail-content {
        width: -webkit-fill-available;
    }
`
