// Styling
import styled from 'styled-components'

export function Composer() {
    return (
        <ComposerContainer>
            {"Composer!"}
        </ComposerContainer>
    )
}

const ComposerContainer = styled.div`
    background-color: #f5f5f5;  
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
`
