import React, { useState, useEffect } from 'react';
import { useForegroundStyle, useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';
import { SFSymbol } from './Utils/SFSymbol.tsx';
import { useAssets } from '../Assets.tsx';

// Define a type for the Asset object
export interface UIImage {
    value?: string;
    named?: string;
    url?: string;
    systemName?: string;
}

export function UIImage({ value, named, url, systemName }: UIImage) {
    const style = useStyle();
    const foregroundStyle = useForegroundStyle();
    const actions = useActions();
    const assets = useAssets();

    let assetName = named ?? value;
    if (assetName && assets.get) {
        let asset = assets.get(assetName);
        if (asset) {
            let dimensions = asset.dimensions ?? { width: undefined, height: undefined };

            let url = asset.url

            let retinaAsset = assets.get(assetName + '@3x') || assets.get(assetName + '@2x');
            if (retinaAsset) {
                url = retinaAsset.url;
            }
 
            return (
                <img style={style} {...actions} width={dimensions.width} height={dimensions.height} src={url} alt={asset.alt} />
            );
        }
    } else if (systemName) {
        
        const fontSize = style.fontSize ? parseFloat(String(style.fontSize)) : 17
        const fill = foregroundStyle.color ? foregroundStyle.color : 'black'

        return (
            <div style={style}>
                <SFSymbol name={systemName} size={fontSize} color={fill}/>
            </div>
        );

    } else if (url) {
        return (
            <img  style={style} {...actions} src={url} alt="placeholder" />
        );
    }

    return null;
}
