import React, { useState, useEffect } from 'react';
import { StyleProvider, StyleContext, useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';
import { useRendererContext } from '../../RendererContext.tsx';    
import { Variables , useVariables} from '../Variable.tsx';

export function Button({ buttonstyle, buttonStyle, children }) {
    const style = {...useStyle()}
    const rendererContext = useRendererContext();
    /** Get variables and functions */
    const { variables } = useVariables();

    var bs = buttonstyle || buttonStyle;

    if (bs && rendererContext.viewCallback ) {
        let buttonStyleView = rendererContext.viewCallback(bs);

        if (buttonStyleView) {
            let props = {
                'configuration.label': children ,
            }
            
            style['textAlign'] = style['textAlign'] || 'center'

            return <Variables {...props} {...style}><StyleProvider style={style}>{buttonStyleView}</StyleProvider></Variables>
        }
    } 
    return (
        <button style={style}>{children}</button>
    );
}
