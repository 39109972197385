import React from 'react';
import styled from 'styled-components'
import { remapChildren, containsNodeOfType } from '../Utils.tsx';
import { useStyle, ClearStyle, ViewStyle, StyleProvider } from './Style.tsx'
import { useActions } from './Actions.tsx';
import { useID } from './ID.tsx';
import { px } from "../Utils.tsx"

interface HStackProps {
    gap?: string;
    align?: string;
    verticalAlignment?: string | null;
}

const shouldForwardProp = (prop) => { return prop == 'children' || prop == 'onClick' }

/**
 * HStack
 */
const Test = styled('div')`
    display: none;
`

function HStack({children, alignment, spacing}) {
    var style   = {...useStyle()}
    var actions = {...useActions()}
    const id    = useID();
    
    // Define the mapping from Spacer to HStackSpacer
    const mapping = {
        [Spacer as any]: <HSpacer />,
    };

    // Remap children using the utility function
    const remappedChildren = remapChildren(children, mapping);

    // If has spacer make the stack expand
    const hasSpacer = containsNodeOfType(children, Spacer, false);
    if (hasSpacer) {
        style['width'] = '-webkit-fill-available';
    }
    
    // Vertical alignment
    var verticalAlignment : string | null = null
    if (alignment) {
        switch (alignment) {
            case "top":
                verticalAlignment = 'flex-start';
                break
            case "bottom":
                verticalAlignment = 'flex-end';
                break
            case "center":  
            verticalAlignment = 'center';
                break;
        }
    }

    return <_HStack className={`hstack ${id}`} key={id} {...actions} verticalAlignment={verticalAlignment} gap={spacing} style={style}><ClearStyle>{remappedChildren}</ClearStyle></_HStack>
}

const _HStack = styled('div').withConfig({ shouldForwardProp: shouldForwardProp })<HStackProps>`
    display: flex;
    gap: ${({ gap }) => px(gap) || '7px'};
    align-items:  ${({ verticalAlignment }) => verticalAlignment || 'center'};
    justify-content:  ${({ align }) => align || 'center'};
`;


/**
 * VStack
 */
interface VStackProps {
    gap?: string;
    align?: string;
    horizontalAlignment?: string | null;
}

function VStack({spacing, alignment, children}) {
    const style = {...useStyle()}
    const actions = {...useActions()}
    const id = useID();

    style['width'] = style.width ?? '-webkit-fit-content';

    // Define the mapping from Spacer to HStackSpacer
    const mapping = {
        [Spacer as any]: <VSpacer />,
    };

    // Remap children using the utility function
    const remappedChildren = remapChildren(children, mapping);

    // If has spacer make the stack expand
    const hasSpacer = containsNodeOfType(children, Spacer, false);
    if (hasSpacer) {
        style['height'] = '-webkit-fill-available';
    }
    
    var horizontalAlignment : string | null = null
    if (alignment) {
        switch (alignment) {
            case "leading":
                horizontalAlignment = 'flex-start';
                break
            case "trailing":
                horizontalAlignment = 'flex-end';
                break
            case "center":  
                horizontalAlignment = 'center';
                break;
        }
    }

    return <ClearStyle><_VStack key={id} className="vstack" {...actions} horizontalAlignment={horizontalAlignment} gap={spacing} style={style}>{remappedChildren}</_VStack></ClearStyle>
}

const _VStack = styled('div').withConfig({  shouldForwardProp: shouldForwardProp })<VStackProps>`
    display: flex;
    gap: ${({ gap }) => px(gap) || '7px'};
    flex-direction: column;
    justify-content:  ${({ align }) => align || 'center'};
    align-items:  ${({ horizontalAlignment }) => horizontalAlignment || 'center'};
`

interface ZStackProps {
    children: any;
    alignment?: string;
}


/**
 * ZStack
 */
function ZStack({children, alignment } : ZStackProps) {
    var style   = {...useStyle()}
    var actions = {...useActions()}
    const id    = useID();

    // Remap children using the utility function
    const remappedChildren = React.Children.map(children, (child) => {  
        return <ZStackElement>{child}</ZStackElement>  
    });

    return <_ZStack className={`zstack ${id}`}  {...actions} style={style} key={id}><ClearStyle>{remappedChildren}</ClearStyle></_ZStack>
}
const _ZStack = styled('div').withConfig({  shouldForwardProp: shouldForwardProp })<VStackProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

function ZStackElement({children}) {
    const style = {...useStyle()};
    style.position = 'absolute'
    return <StyleProvider style={style}>{children}</StyleProvider>;

    // return <_ZStackElement>{children}</_ZStackElement>
}

const _ZStackElement = styled('div')`
    position: absolute;
`

function Spacer() {
    return <HSpacer/>
}

/**
 * Spacer
 */
interface HSpacerProps {
   
}
function HSpacer() {
    return <_HSpacer className="hspacer" />
}

const _HSpacer = styled('div').withConfig({
    shouldForwardProp: shouldForwardProp
})<HSpacerProps>`
    flex-grow: 1;
`


/**
 * Spacer
 */
interface VSpacerProps {
   
}
function VSpacer() {
    return <_VSpacer className="vspacer" />
}

const _VSpacer = styled('div').withConfig({
    shouldForwardProp: shouldForwardProp
})<VSpacerProps>`
    flex-grow: 1;
`

export { HStack, VStack, ZStack, Spacer };