
import React, { useEffect, Suspense } from 'react'

import { 
    YapContextMenuOption,
    YapContextMenuDivider,
    VStack
  } from '@yapstudios/yap-content-builder'

import styled from 'styled-components'
import Markdown from 'react-markdown'
import AST from "../YapDesigner/YapUI/YapUIAST.js";


export function ComponentInfo({ component, previewStates, previewIndex, setPreviewIndex }) {

    // const [component, setComponent] = React.useState(null);

    // useEffect(() => {   
    //     try {
    //         let content = JSON.parse(yapui) 
    //         const component = AST.findComponent(content)    
    //         setComponent(component)
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }, [yapui])

    return component ? (
        <ComponentInfoContainer>
            <ComponentInfoContent component={component} previewStates={previewStates} previewIndex={previewIndex}  setPreviewIndex={setPreviewIndex}/>
        </ComponentInfoContainer>
    ) : null
}

export function ComponentInfoContent({ component, previewStates, previewIndex, setPreviewIndex }) {
    if (component == null || component.metadata == null ) { return null }

    
    const stateContent = (previewStates && previewStates.length > 1) ? previewStates.map( (state, idx) => {
        return (
            <YapContextMenuOption title={state.title} checked={state.index == previewIndex} onClick={ () => {
                setPreviewIndex(idx)   
            }}/>
        )
    }) : null


    return (
        <>
            <VStack align={"leading"} spacing="10" padding="20" paddingBottom="0">
                <VStack align={"leading"} spacing="5" padding="0" paddingBottom="0">
                    <h1 className="info-title">{component.metadata.name ?? component.name}</h1>
                    <caption>{component.metadata.version}</caption>
                </VStack>
                
                {component.metadata ? <Markdown>{component.metadata.description}</Markdown> : null}
            </VStack>
            {stateContent && <YapContextMenuDivider/>}
            {stateContent}
        </>
    )
}


const ComponentInfoContainer = styled.div`
    font-size: 15px;
    line-height: 22px;

    caption {
        font-size: 12px;
        color: #777;
    }

    p:first-child {
        margin-top: 5px;
    }

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    p:last-child {
        margin-bottom: 15px;
    }

    h1 {
        font-size: 20px;   
    }

    li {
        margin-top: 10px;
        margin-bottom: 10px;
    }
        
    h1.info-title {
        margin: 0px;
    }

    h2 {
        font-size: 17px;
    }

    h3 {
        font-size: 15px;
    }

    h4 {
        font-size: 13px;
    }
`
