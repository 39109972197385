import React, { useEffect } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';

export function CodeEditor({ content, path, setContent, language }) {
    const monaco = useMonaco();

    const onEditorChange = (value, event) => {
        setContent(value);
    }

    const handleEditorValidation = (markers) => {
        // model markers
        markers.forEach((marker) => console.log('onValidate:', marker.message));
    }

    useEffect(() => {
        if (monaco) {
            console.log('here is the monaco instance:', monaco);
        }
    }, [monaco]);

    return <Editor
        path={path}
        height={"100%"}
        defaultValue={content}
        loading={""}
        options={{
            codeLens: false,
            colorDecorators: true,
            detectIndentation: false,
            tabSize: 4,
            scrollBeyondLastLine: true,
            fontSize: 13,
            fontFamily: 'monospace',
            scrollbar: {
                useShadows: false,
                horizontalScrollbarSize: 7,
                verticalScrollbarSize: 7,
                vertical: 'auto',
                horizontal: 'auto'
            },
            minimap: { enabled: false }
        }}
        defaultLanguage={language}
        onValidate={handleEditorValidation}
        onChange={onEditorChange} />;
}
