import React, { createContext, useContext, ReactNode } from 'react';
import { useStyle, StyleProvider } from '../Style.tsx';
import { useVariableNode } from '../Variable.tsx';

export function useColorNode(value : React.ReactNode) {
    // Check if the value is a React element of type 'Color'
    if (React.isValidElement(value)) {
        // if (value.type === SetVariables) 
        //     return value//React.cloneElement(value, { props: { ...value.props, content: children }} as any);   
        if (value.type === Color) {
            return value.props['value']
        } else {
            return value
        }   
    } else if (typeof value === 'string') {
        return value
    } else {
        return null
    }
}

export function Color({ value, content }) {
    var style = {...useStyle()};
    var firstChild = useVariableNode(value ? value : null);

    style.backgroundColor = firstChild as string;

    // If content is passed, apply the color as a background color style
    if (content) {  
        return <StyleProvider style={style}>{content}</StyleProvider>;            

    // Otherwise present the color
    } else {
        return <div style={style}>&nbsp;</div>;
    }

}