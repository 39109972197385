import React from 'react';
import { useVariableNode,  Variables } from './Variable.tsx';

export function ForEach({ value,  children }) {
    var value = useVariableNode(value);

    if (value == null) { return "no data" }

    if (!Array.isArray(value)) {
        value = [value]
    }

    let reactChildren = React.Children.toArray(children).filter((child) => { return React.isValidElement(child) });
    
    console.log('ForEach', value)
    return value.map((item, index) => {
        
        const args = {  index: index,  item: item as any, element: item as any } as any;

        return React.Children.map(reactChildren, (child) => {
            if (React.isValidElement(child)) {
                console.log(child)
                return <Variables key={index} {...args}>{React.cloneElement(child, {...child.props, ...args}, child.props.children)}</Variables>
            } else {
                return null
            }
        })
    });
}

export function Iterator({ property, children, item, key, index })  {
    
    let args = {}
    args[property] = item
    args['index'] = index
    let reactChildren = React.Children.toArray(children).filter((child) => { return React.isValidElement(child) });

    let firstChild = children ? reactChildren[0] as React.ReactElement :  null; 
    if (firstChild) {
        return <Variables {...args}>{React.cloneElement(firstChild, args)}</Variables>
    } else {
        return null
    }
}