/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'

// YapContentBuilder components
import { 
         YapPage, 
         YapHeader,
         YapHeaderButton,
         YapNotification,
         YapContextMenu,
         YapContextMenuOption,
         useYapPageState,
         YapUI,
         SegmentedControl
       } from '@yapstudios/yap-content-builder'

import {
        getPanelElement,
        getPanelGroupElement,
        getResizeHandleElement,
        Panel,
        PanelGroup,
        PanelResizeHandle,
       } from "react-resizable-panels";
    

// Editor UI
import { CodeEditor } from './CodeEditor/CodeEditor.js'
import { Renderer } from './YapUI/Renderer/Renderer.js'
import { FileSidebar } from './FileSidebar.js';
// import { AssetsBrowser } from './AssetsBrowser.js'
import { Loading } from '../Loading.js'
import { EditorDetail } from "./EditorDetail.js"
import { ComponentGrid } from './ComponentGrid.js'
import { ComponentInfo } from './ComponentInfo.js';
import { Assets, AssetsDetail, AssetUploaderButton, useAssets } from './Assets.js'
import { MenuContentShare, MenuContentPresent, MenuContentPreview } from './AppMenu.js';
import { menuAction, MenuMain } from '../AppMenu.js'
import { Composer } from './Composer.js'

// Styling
import styled from 'styled-components'

// Data / Firebase
import { loadTypes, initBucketData, updateTypes , loadAssets } from '../AppData.js'
import { AuthProvider } from "../useAuth.js"

// React router
import { useNavigate, useLoaderData, useParams, Await } from "react-router-dom";
import { ProtectedRoute } from "../ProtectedRoute.js"

// YapUI
import { jsxStringToReact } from './YapUI/JSXParser.js'
import { YapUIDecoder } from './YapUI/YapUIDecoder.js'
import { YapUIEncoder as YapUIJSXEncoder } from './YapUI/JSXParser.js'
import { YapUIEncoder as YapUIJSEncoder } from './YapUI/JSParser.js'
import { parseSwiftUI } from './YapUI/SwiftUIParser.js'

// JSON5 Parsing
import JSON5 from 'json5'

// Util
import { v4 as uuidv4 } from 'uuid';
import { useSessionStorage, useLocalStorage } from 'usehooks-ts'

// Key handling
import useKeyCombo from './Util/useKeyCombo.js'

// Default Content
import { newFileTemplate } from './FileTemplates.js';

import { useAppMirror } from '../AppMirror.js'

/**
* Loader
*/
export const ContentEditorAuthLoader = (props) => {
    const { userPromise } = useLoaderData();
    const params = useParams();
    console.log('ContentBuilderAuthLoader')
    
    return (
        <AuthProvider>
            <ProtectedRoute>
                <Suspense fallback={<Loading/>}>
                    <Await
                        resolve={userPromise({params: params})}
                        // errorElement={<div>Something went wrong!</div>}
                        children={(data) => (
                            <ContentEditor types={data}/>
                        )}
                    />
                </Suspense>
            </ProtectedRoute>
        </AuthProvider>
    );
};

export async function contentEditorLoader({ params }) {
    // Initialise info from user 
    initBucketData()
    const types = await loadTypes();
    return types
}


/**
 * View Model
 */

export const ItemType = {
    yapui: "component", 
    folder: "folder",
    composer: "composer",
    assets: "assets"
}

/**
 * Encodes component data stored in firebase from a yapui js content string
 */
function encodeComponent(content, name) {
    let component = YapUIJSEncoder(content, { name: name })

    const body = JSON5.stringify(component.body, null, 2)
    // Construct 
    let data = { json: body,
                 body: body,
                 previews: component.previews.map((preview) => {
                    return { ...preview,       
                            body: JSON5.stringify(preview.body, null, 2)
                    }
                }),
                metadata: component.props
              }

    // delete undefined keys in data recursively
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key])

    return data
}


function useContentEditor({ types }) {
    let defaultTypeItems = [
        { title: "NewType", id: "NewType", content: "", json: "" },
    ]

    const [selectedId, setSelectedId] = useState()

    let bucketData = initBucketData()
    const applicationId = bucketData ? bucketData.institutionId : null

    /**
     * Items
     */
    const [items, setItems] = useState(types ?? defaultTypeItems)

    /**
     * 'All items' which may include other types we're not saving, such as external depenancies.
     */
    const allItems = [...items];

    // Selected Item
    const selectedItem = selectedId != null ? items.find((item) => item.path === selectedId) : null

    // Incremented when there's a change to the js content
    const [contentVersion, setContentVersion] = useState(0)

    // Assets
    // const [showAssets, setShowAssets] = useState(false)
    // const [assets, setAssets] = useState([])

    // UI Publish Notification
    const [showPublishNotification, setShowPublishNotification ] = React.useState(false);

    // Preview States
    //const [previewStates, setPreviewStates ] = React.useState([]);
    const previewStates = selectedItem ? selectedItem.previews ?? [] : []
    const [previewIndex, setPreviewIndex] = React.useState(null);

    // Frame
    const frameIdenfifier = (selectedItem && selectedItem.frame) ? selectedItem.frame : null

    // Source type
    const sourceType = extractViewType(selectedItem ? selectedItem.path : null)

    // View Name
    const viewName = extractViewName(selectedItem ? selectedItem.path : null, false)

    // Preview modal
    const [showPreviewModal, setShowPreviewModal] = useState(false)

    const defaultFrame = items.find((item) => item.name === 'DefaultFrame')

    // App Mirror
    const [mirrorSessionId, setMirrorSessionId] = useLocalStorage("mirrorSessionId", null);

    const mirror = useAppMirror(mirrorSessionId)

    const startMirrorSession = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        let length = 6;
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }
          
        setMirrorSessionId(code)
    }

    const stopMirrorSession = () => {
        setMirrorSessionId(null)
    }

    /**
     * Determine type. TODO: migrate to property
     */
    var selectedItemType = null
    if (selectedId) {
        if (selectedId.endsWith('/') || (selectedItem?.type == ItemType.composer)) {
            selectedItemType = ItemType.folder
        } else if (selectedId.endsWith('.composer') || selectedItem?.type == ItemType.composer) {
            selectedItemType = ItemType.composer    
        } else if (selectedId.endsWith('.assets') || selectedItem?.type == ItemType.assets) {
            selectedItemType = ItemType.assets
        } else {
            selectedItemType = ItemType.yapui
        }
    }

    const mirrorSyncSelected = (item) => {
        if (item && selectedItemType == ItemType.yapui) {   
            try {
                let data = {...item}
                delete data['jsonContent']
                delete data['states']
                delete data['title']
                
                // delete undefined keys
                Object.keys(data).forEach(key => data[key] === undefined && delete data[key])   

                mirror.syncSelected(data)
            } catch (e) {
                console.error(e)
            }            
        }
    }

    const mirrorSyncComponents = () => {    
        mirror.syncComponents(items.filter( (item) => item.json != null).map((item) => {
            return item
        }))
    }

    React.useEffect(() => {
        setPreviewIndex(0)
    }, [selectedId])

    React.useEffect(() => {
        mirror.syncFrame(getLastPart(frameIdenfifier))
    }, [frameIdenfifier])

    React.useEffect(() => { 
        mirrorSyncSelected(selectedItem)
        mirrorSyncComponents()
        mirror.syncPreview(previewIndex)
        
    }, [selectedId, mirrorSessionId])

    React.useEffect(() => { 
        mirror.syncPreview(previewIndex)
    }, [previewIndex])

    /**
     * Callback when code editor content is updated
     */
    const updateContent = (content) => {

        var item = {...selectedItem}
        
        // Store the original code in the content prop
        item.content = content
                
        console.log('updateContent', item)
        switch (sourceType) {

            /**
             * Generate json from content string    
             */
            case "js":
                const component = encodeComponent(item.content, item.name);

                if (component) {

                    // Serialise to json                    
                    try {                    
                        item = {
                            type: 'component',
                            ...item, 
                            ...component
                        }
                        
                    } catch (e) {
                        console.error('Error serialising json', e)
                    }
                }

                break;
            case "swiftui":
                const swiftui = parseSwiftUI(item.content)                
                console.log(swiftui )
                break
            case "jsx":
                const jsx = jsxStringToReact(item.content ? item.content : null, null, nameCallback)

                // Generate YapUI JSON from JSX
                const yapui = YapUIJSXEncoder(jsx, { nameCallback })

                // Serialise to json
                item.json =  JSON.stringify(yapui, null, 2)

                break;
        }
        
        let newItems = items.map((existingItem) => {
            if (existingItem.path === selectedId) {
                return {...item}
            }
            return existingItem
        })

        mirrorSyncSelected(item)

        setItems(newItems)
        setContentVersion(contentVersion + 1)
    }

    /**
     * Updates an existing item 
     */
    const updateItem = (item) => {
        let newItems = items.map((existingItem) => {
            if (existingItem.path === selectedId) {
                return {...item}
            }
            return existingItem
        })
        setItems(newItems)
    }

    /**
     * Called when JSON is updated directly
     */
    const updateJSON = (json) => {  
        var item = {...selectedItem}
        try {
            const jsonObject = JSON5.parse(json)
            item.json = JSON.stringify(jsonObject, null, 2)
            let newItems = items.map((existingItem) => {
                if (existingItem.path === selectedId) {
                    return {...item}
                }
                return existingItem
            })
            setItems(newItems)
        } catch (e) {
            console.error('updateJSON', e)
        }
    }

    const saveContent = () => {
        updateTypes(items)
        setShowPublishNotification(true)
    }

    const addNewComponent = (parentPath) => {
        let userInput = prompt("Component Name");
        if (!(userInput && userInput.length > 0)) {
            return;
        }

        // Get view name from full path
        let name = extractViewName(userInput, false)

        // Generate new file template
        let template = newFileTemplate({ name: name })
        
        let component = encodeComponent(template, name)

        let newItem = { name: name, 
                        path: parentPath ? parentPath + userInput : userInput,
                        content: template,
                        type: 'component',
                        id: uuidv4(),             
                        ...component
                    }

        // Update and select
        setItems([...items, newItem])
        setSelectedId(newItem.path)
    }

    const addNewFolder = (parentPath) => {
        let userInput = prompt("Folder Name");

        if (!(userInput && userInput.length > 0)) {
            return;
        }

        // Get view name from full path
        let name = extractViewName(userInput, false)

        // Construct 
        let newItem = { path: parentPath ? parentPath + userInput : userInput, 
            name: name, 
            title: userInput,  // deprecate
            id: uuidv4(), 
            type: 'folder',
            isFolder: true }

        // Update and select
        setItems([...items, newItem])
        setSelectedId(newItem.path + '/')

    }

    const addNewAssets = (parentPath) => {
        //let userInput = prompt("Assets Name");
        let name = 'Assets'

        // Construct 
        let newItem = { path: (parentPath ? parentPath + name : name) + '.assets', 
            name: name, 
            title: name,  // deprecate
            id: uuidv4(), 
            type: 'assets' }

        // Update and select
        setItems([...items, newItem])
        setSelectedId(newItem.path + '/')
    }

    const onDelete = (id) => {
        let isFolder = id.endsWith('/')
        let folderWithoutEndSlash = isFolder ? id.slice(0, -1) : id
        
        // Show a confirmation alert
        //let item = items.find((item) => (item.path == id || (isFolder && item.path == folderWithoutEndSlash)))

        if (!window.confirm(`Delete ${id} ?`)) {
            return
        }
        let newItems = items.filter((item) => {
            if (isFolder) {
                return !item.path.startsWith(folderWithoutEndSlash)
            } else {
                return item.path !== id
            }
        })
        setItems(newItems)
        setSelectedId(null)
    }

    const onRename = (id, path) => {

        let isFolder = id.endsWith('/')
        let folderWithoutEndSlash = isFolder ? id.slice(0, -1) : id
        
        let newItems = items.map((item) => {
            if (isFolder && item.path.startsWith(folderWithoutEndSlash)) { 
                let itemPath = item.path.replace(folderWithoutEndSlash, path)
                item = {...item,
                    title: itemPath,
                    path: itemPath
                }
                setSelectedId(path + '/')
            } else if (item.path == id) {
                let name = extractViewName(path, false)
                item = {...item, 
                        title: path, 
                        path: path, 
                        name: name }
                setSelectedId(path)
            }
            return item
        })
        setItems(newItems)
    }

    /**
     * Callback that preserves the case of the name
     */
    const nameCallback = (tagName) => {
        for (const item of items) {
            const parts = (item.name ?? item.title).split('/')
            const itemName = String(parts[parts.length - 1])
            if (itemName.toLowerCase() === tagName.toLowerCase()) {
                return itemName
            }
        }
        return null
    }

    /**
     * Callback that resolves jsx for a specific view name
     */
    const viewCallback = (name) => {
        return viewForComponentName(name, allItems)
    }

    const jsCallback = (name) => {
        const file = findView(name + ".js", allItems)
        if (file) {
            return file.content
        } else {
            return null
        }
    }

    /**
     * Assets
     */
    const getAsset = (key, name) => {    

        // Find the item that wants the asset
        let item = findView(name, items)

        // Get it's path
        let currentViewPath = extractViewPath(item?.path)

        // Find valid asset libraries within that path hierarchy
        let allAssets   = items.filter((item) => item.type == ItemType.assets) 
        let validAssets = allAssets.filter((item) => item.path.startsWith(currentViewPath) == true)  

        for (var assetLibrary of validAssets) {
            let assetWithTitle = Object.values(assetLibrary.assets).find((asset) => asset.title == key)
            if (assetWithTitle) {
                console.log(assetWithTitle)
                return {
                    url: assetWithTitle.src,
                    id: assetWithTitle.id,
                    dimensions: assetWithTitle.dimensions,
                    type: assetWithTitle.type,
                    size: assetWithTitle.size,
                    crop: assetWithTitle.crop
                }
            }
        }

        return null
    }


    return { updateContent, viewCallback , nameCallback, onRename, onDelete, saveContent, selectedId,setSelectedId,selectedItem, frameIdenfifier, 
        setSelectedId, previewStates, selectedItem, previewIndex, setPreviewIndex, items, setItems, allItems, defaultFrame, selectedItem, 
        contentVersion, updateJSON, contentVersion, showPublishNotification, setShowPublishNotification, 
        // showAssets, setShowAssets,
        applicationId, showPreviewModal, setShowPreviewModal, jsCallback,
        selectedItemType, 
        addNewComponent, addNewFolder, addNewAssets,
        mirrorSessionId, setMirrorSessionId, startMirrorSession, stopMirrorSession,
        updateItem,
        getAsset }    
}


function getLastPart(path) {
    if (path == null) { return null }   
    // Split the path by '/' and return the last element
    const parts = path.split('/');
    return parts[parts.length - 1];
}

/**
 * Key Handler
 */
function useEditorKeyCombos({ previewStates, setPreviewIndex, setShowPreviewModal, saveContent}) {
    /**
   * Keycombos
   */

  // Previews
  const previewKeys = previewStates.reduce((acc, state, idx) => {
      acc[`Meta+Shift+Digit${idx + 1}`] = () => setPreviewIndex(idx);
      return acc;
  }, {});

  
  // System
  useKeyCombo({
       'Meta+S' : saveContent,
       'Meta+Shift+F' : () => { setShowPreviewModal(true) },
      ...previewKeys })
}

/**
 * View
 */
export function ContentEditor({ types }) {

    // Get view model
    const editor = useContentEditor({ types: types})
    
    const { selectedId, setSelectedId, selectedItem,
            items, 
            updateContent, updateItem, viewCallback, jsCallback, 
            addNew, onRename, onDelete, saveContent, updateJSON,
            frameIdenfifier, 
            setPreviewIndex, previewIndex, contentVersion,
            previewStates, 
            showPublishNotification, setShowPublishNotification, 
            // showAssets, setShowAssets,
            setShowPreviewModal,
            selectedItemType, 
            startMirrorSession, stopMirrorSession } = editor

    // Get assets view model
    const assets = useAssets({ assets: selectedItem?.type == ItemType.assets ? selectedItem.assets ?? [] : [], setAssets: (assets) => {
        updateItem({ ...selectedItem, assets: assets })
        console.log('updating assets', assets)  
    }})    


    // Handle keys
    useEditorKeyCombos({ previewStates: previewStates,  
                         saveContent: saveContent,
                         setPreviewIndex: setPreviewIndex, 
                         setShowPreviewModal: setShowPreviewModal })

    const actions = [

        <YapHeaderButton key="share" disabled={false} content={<MenuContentPreview editor={editor}/>}>{"Share"}</YapHeaderButton>,

        <YapHeaderButton key="mirror" content={<MenuContentPresent editor={editor}/>}>{"Mirror"}</YapHeaderButton>,

        <YapHeaderButton key="save" onClick={() => {
            saveContent()
        }}>{"Publish"}</YapHeaderButton>

    ]

    /**
     * Sidebar
     */
    const fileSidebar = <FileSidebar editor={editor} />

    console.log('Previe States', previewStates)
    /**
     *  Renderer
     */
    const yapui = selectedItem ? selectedItem.json : null
    const renderer = <Renderer yapui={yapui} 
                               componentName={selectedItem ? selectedItem.name : null}
                               getAsset={editor.getAsset}
                               viewCallback={viewCallback} 
                               jsCallback={jsCallback}
                               frame={frameIdenfifier} 
                               previews={previewStates}
                               previewIndex={previewIndex} 
                               allowDefaultFrame={selectedItem?.path.startsWith('PreviewFrames') == false}/>

    /**
     * JSON Preview
     */
    const jsonPreview =  <JSONPreview setJSON={updateJSON} contentVersion={contentVersion} path={selectedItem ? selectedItem.path ?? selectedItem.title : null} json={selectedItem ? selectedItem.json : null}/>

    /**
     * Component Info
     */
    const componentInfo = <ComponentInfo component={selectedItem}  />

    /**
     * Folder Content
     */
    const folderContent = <ComponentGrid editor={editor} items={items.filter( item => {
        return selectedId ? item.path.startsWith(selectedId) : true
    })}/> 

    var centerContent = null
    var detailContent = null
    var fileActions = []

    switch (selectedItemType) {
        case ItemType.folder:
            centerContent = folderContent
            detailContent = null;
            break


        /**
         * Code Eidtor
         */            
        case ItemType.yapui:    
            const codeEditorContent = selectedItem ? selectedItem.content : ""
            const codeEditorLanguage = "javascript"
            const codeEditor =  <CodeEditor path={selectedItem ? (selectedItem.path ?? selectedItem.title) : null} language={codeEditorLanguage} content={codeEditorContent} setContent={updateContent}/>
            detailContent = <EditorDetail jsonPreview={jsonPreview} editor={editor} renderer={renderer} componentInfo={componentInfo} /> 
            centerContent = codeEditor
            break

        /**
         * Composer
         */
        case ItemType.composer:
            centerContent = <Composer/>;
            detailContent = null;
            break

        /**
         * Assets
         */
        
        case ItemType.assets:
            fileActions = [
                <YapHeaderButton key="add" onClick={() => { assets.setShowUploadAsset(true) }}><AssetUploaderButton assets={assets}/></YapHeaderButton>
            ]
            detailContent = <AssetsDetail assets={assets} />;
            centerContent = <Assets assets={assets}  />;
            break
    }

    return (
        <YapPage fullscreen={true} >
            <YapNotification title="Published" icon={"success"} show={showPublishNotification} setShow={setShowPublishNotification}/>
            <YapHeader fixed={true} titleLeft={true} largeTitle={false} title={"Designer"} headerActions={actions} headerActionsLeft={[menuAction]}></YapHeader>

            <div className={`layout layout-app with-top-toolbar`}>
                <PanelGroup direction="horizontal" id="group" >

                    <Panel id="left-panel" defaultSize={15} minSize={15}>
                        {fileSidebar}
                    </Panel>

                    <PanelResizeHandle className="resize-handle" id="resize-handle-left" />

                    <Panel id="center-panel" >
                        <section className="layout-center" >
                            <YapHeader titleLeft={true} largeTitle={false} headerActions={fileActions} title={selectedId ?? ''}></YapHeader>   
                            <div className="layout-center-content">                            
                                {centerContent}
                            </div>
                        </section>
                    </Panel>

                    <PanelResizeHandle className="resize-handle" id="resize-handle-right" />

                    <Panel id="right-panel"  defaultSize={10} minSize={20}>
                       {detailContent}
                    </Panel>

                </PanelGroup>
            </div>
            
            {/* <AssetsBrowser show={showAssets} setShow={setShowAssets}  /> */}

        </YapPage>
    )
}

function JSONPreview({ contentVersion, setJSON, path, json }) {    
    return (
        <CodeEditor content={json} path={path + '/' + contentVersion} language={"json5"} setContent={setJSON}/>
    )
}

const extractViewName = (title, lowercase = true) => {
    if (title == null) { return null }
    const parts = String(title).split('/')
    var itemName = String(parts[parts.length - 1])

    const extensionParts = itemName.split('.')
    itemName = extensionParts[0]

    if (lowercase) {
        itemName = itemName.toLowerCase();
    } 

    return itemName;
}

const extractViewPath = (path) => {
    if (path == null) { return null }
    const parts = String(path).split('/')
    return parts.splice(0, parts.length - 1).join('/')
}

const extractViewType = (title) => {
    if (title == null) { return null }
    const extensionParts = String(title).split('.')
    return extensionParts[1] ?? "js"
}

export function viewForComponentName (name, items) {
    let view = findView(name, items)

    if (!(view && view.json)) {
        console.log(`viewForComponentName: not found ${name}`);
        return null;
    }    

    try {
        let content = JSON5.parse(view.json);
        console.log(content)
        return YapUIDecoder(content, (name) => {
            return viewForComponentName(name, items)
        }); 
        // let component = AST.findComponent(content);
        // return YapUIDecoder(component ? component.body : content, (name) => {
        //     viewForComponentName(name, items)
        // });                
    } catch (e) {
        console.log('viewForComponentName: error', e)
        return null;
    }
}


export const findView = (name, items) => {
    for (const item of items) {

        const itemName = extractViewName(item.name ?? item.title)

        // Match on full or short title
        if (String(item.title).toLowerCase() == String(name).toLowerCase() || 
            String(itemName).toLowerCase() === String(name).toLowerCase() ||
            String(item.path).toLowerCase() === String(name).toLowerCase()) {
            return item
        }
    }
    console.log('did not find', name);

    return null                    
}
