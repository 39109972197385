import React, { useState, useEffect } from 'react';
import { useStyle, useForegroundStyle } from '../Style.tsx';
import styled from 'styled-components'
import { ClearStyle } from '../Style.tsx';
export default function Text({ children }) {
    const style = useStyle()
    const foregroundStyle = useForegroundStyle()
    
    return (
        <_P className="text" style={{...style, ...foregroundStyle}}><ClearStyle>{children}</ClearStyle></_P>
    );
}


const _P = styled('p').withConfig({ shouldForwardProp: () => { return true } })`
    margin: 0px;
`