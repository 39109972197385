import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'
import styled from 'styled-components'
import { Renderer } from './YapUI/Renderer/Renderer';
export function ComponentGrid({ items, editor }) {
    let environment = {
        'environment.screen': 'mobile',
        'environment.preview' : 'thumbnail'
    }
    return (
        <ComponentGridContainer>
            {items.map((component, index) => {
                return <ComponentGridItem environment={environment} editor={editor} item={component} />
            })}
        </ComponentGridContainer>
    );

}

export function ComponentGridItem({ item, environment, editor }) {

    const renderer = <Renderer yapui={item.json}
                                viewCallback={editor.viewCallback}
                                jsCallback={editor.jsCallback}
                                frame={item.frame}
                                previews={item.previews}
                                environment={environment}
                                componentName={item.name}
                                previewIndex={0} />

        
    return (
        <ComponentGridItemContainer onClick={ () => {
            editor.setSelectedId(item.path)
        }}>
            <ComponentGridItemRender>{renderer}</ComponentGridItemRender>
            <span className="title">{item.name}</span>
        </ComponentGridItemContainer>
    );
}

const ComponentGridItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    justify-content: space-between;
    align-items: center;

    gap: 10px;
    cursor: pointer;

    .title {
        font-size: 13px;
        justify-self: flex-end;
    }
`

const ComponentGridItemRender = styled.div`
    height: 300px;
    width: 300px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #e3e3e3;  
`

const ComponentGridContainer = styled.div`
    background-color: #fcfcfc;

    display: grid;
    overflow-x: scroll;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive columns */
    grid-template-rows: 380px;
    grid-gap: 20px;
    padding: 20px;
    justify-items: flex-start;
    align-items: flex-start; 
    padding-top: 25px;
`;