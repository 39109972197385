import React, { useState, useEffect } from 'react';
import { StyleProvider, StyleContext, useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';
import { useRendererContext } from '../../RendererContext.tsx';    
import { Variables , useVariables} from '../Variable.tsx';

export function TextField({ textFieldStyle, text, prompt, children }) {
    const style = {...useStyle()}
    const rendererContext = useRendererContext();
    /** Get variables and functions */
    const { variables } = useVariables();

    var ts = textFieldStyle;

    if (ts && rendererContext.viewCallback ) {
        let textFieldStyleView = rendererContext.viewCallback(ts);

        if (textFieldStyleView) {
            let props = {
                'configuration.label': <_TextFieldLabel prompt={prompt}/>
            }
            
            style['textAlign'] = style['textAlign'] || 'center'

            return <Variables {...props} {...style}><StyleProvider style={style}>{textFieldStyleView}</StyleProvider></Variables>
        }
    } 
    return (
        <input type="textfield" style={style}></input>
    );
}

function _TextFieldLabel({ prompt } : { prompt?: string }) {
    const style = {...useStyle()}
    return (<input type="textfield" placeholder={prompt} style={style}></input>);
}
