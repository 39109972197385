/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'
import { VStack, YapHeaderButton, YapContextMenuOption, YapContextMenuDivider } from '@yapstudios/yap-content-builder'

// React router
import { useNavigate } from "react-router-dom";

export const menuIcon = (
    <svg style={{marginTop: "2px" }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  style={{"fill": "var(--content-builder-ui-tint-color)"}} d="M1.90771 7.91779C1.32015 7.91779 0.87793 7.76935 0.581055 7.47247C0.28418 7.16941 0.135742 6.71173 0.135742 6.09943V1.95245C0.135742 1.34015 0.28418 0.885559 0.581055 0.588684C0.87793 0.291809 1.32015 0.143372 1.90771 0.143372H6.13818C6.72575 0.143372 7.16797 0.291809 7.46484 0.588684C7.76172 0.885559 7.91016 1.34015 7.91016 1.95245V6.09943C7.91016 6.71173 7.76172 7.16941 7.46484 7.47247C7.16797 7.76935 6.72575 7.91779 6.13818 7.91779H1.90771ZM11.2222 7.91779C10.6284 7.91779 10.1831 7.76935 9.88623 7.47247C9.58936 7.16941 9.44092 6.71173 9.44092 6.09943V1.95245C9.44092 1.34015 9.58936 0.885559 9.88623 0.588684C10.1831 0.291809 10.6284 0.143372 11.2222 0.143372H15.4434C16.0309 0.143372 16.4731 0.291809 16.77 0.588684C17.0669 0.885559 17.2153 1.34015 17.2153 1.95245V6.09943C17.2153 6.71173 17.0669 7.16941 16.77 7.47247C16.4731 7.76935 16.0309 7.91779 15.4434 7.91779H11.2222ZM1.90771 17.223C1.32015 17.223 0.87793 17.0745 0.581055 16.7776C0.28418 16.4808 0.135742 16.0262 0.135742 15.4139V11.2576C0.135742 10.6515 0.28418 10.2 0.581055 9.90314C0.87793 9.60008 1.32015 9.44855 1.90771 9.44855H6.13818C6.72575 9.44855 7.16797 9.60008 7.46484 9.90314C7.76172 10.2 7.91016 10.6515 7.91016 11.2576V15.4139C7.91016 16.0262 7.76172 16.4808 7.46484 16.7776C7.16797 17.0745 6.72575 17.223 6.13818 17.223H1.90771ZM11.2222 17.223C10.6284 17.223 10.1831 17.0745 9.88623 16.7776C9.58936 16.4808 9.44092 16.0262 9.44092 15.4139V11.2576C9.44092 10.6515 9.58936 10.2 9.88623 9.90314C10.1831 9.60008 10.6284 9.44855 11.2222 9.44855H15.4434C16.0309 9.44855 16.4731 9.60008 16.77 9.90314C17.0669 10.2 17.2153 10.6515 17.2153 11.2576V15.4139C17.2153 16.0262 17.0669 16.4808 16.77 16.7776C16.4731 17.0745 16.0309 17.223 15.4434 17.223H11.2222Z" />
    </svg>
)

export const menuAction = <YapHeaderButton key="menu" alignContent="left" content={<MenuMain/>}>{menuIcon}</YapHeaderButton>


export function MenuMain({ }) {
    const navigation = useNavigate()

    const exitIcon = (
        <svg width="20" height="19" viewBox="0 1 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{ "fill": "var(--content-builder-ui-tint-color)" }} d="M2.68994 18.2728C1.83219 18.2728 1.1875 18.0569 0.755859 17.6253C0.329753 17.1937 0.116699 16.5434 0.116699 15.6746V3.08234C0.116699 2.21906 0.329753 1.56883 0.755859 1.13165C1.1875 0.694478 1.83219 0.475891 2.68994 0.475891H11.5054C12.3631 0.475891 13.005 0.694478 13.4312 1.13165C13.8628 1.56883 14.0786 2.21906 14.0786 3.08234V7.10822H12.7422V3.10724C12.7422 2.6922 12.6315 2.374 12.4102 2.15265C12.1943 1.92576 11.8706 1.81232 11.439 1.81232H2.75635C2.32471 1.81232 1.99821 1.92576 1.77686 2.15265C1.56104 2.374 1.45312 2.6922 1.45312 3.10724V15.6497C1.45312 16.0648 1.56104 16.383 1.77686 16.6043C1.99821 16.8257 2.32471 16.9363 2.75635 16.9363H11.439C11.8706 16.9363 12.1943 16.8257 12.4102 16.6043C12.6315 16.383 12.7422 16.0648 12.7422 15.6497V11.6404H14.0786V15.6746C14.0786 16.5434 13.8628 17.1937 13.4312 17.6253C13.005 18.0569 12.3631 18.2728 11.5054 18.2728H2.68994ZM7.79492 10.0301C7.62337 10.0301 7.47396 9.96645 7.34668 9.83917C7.2194 9.70636 7.15576 9.55141 7.15576 9.37433C7.15576 9.19725 7.2194 9.0423 7.34668 8.90948C7.47396 8.77667 7.62337 8.71027 7.79492 8.71027H16.3198L17.5649 8.76837L16.9756 8.20392L15.6392 6.9422C15.5063 6.82599 15.4399 6.67381 15.4399 6.48566C15.4399 6.31411 15.4925 6.17299 15.5977 6.06232C15.7083 5.94611 15.8494 5.888 16.021 5.888C16.1815 5.888 16.3281 5.95441 16.4609 6.08722L19.167 8.89288C19.25 8.97589 19.3053 9.05613 19.333 9.13361C19.3662 9.21108 19.3828 9.29132 19.3828 9.37433C19.3828 9.45734 19.3662 9.53758 19.333 9.61505C19.3053 9.69253 19.25 9.77277 19.167 9.85577L16.4609 12.6614C16.3281 12.7887 16.1815 12.8524 16.021 12.8524C15.8494 12.8524 15.7083 12.7943 15.5977 12.678C15.4925 12.5618 15.4399 12.4179 15.4399 12.2464C15.4399 12.0638 15.5063 11.9144 15.6392 11.7982L16.9756 10.5447L17.5649 9.98029L16.3198 10.0301H7.79492Z" />
        </svg>
    )

    return (
        <div style={{"width" : "250px"}}>
            <YapContextMenuOption width={400} title={"Designer"} onClick={() => {
                navigation("/editor")
             }}></YapContextMenuOption>
            <YapContextMenuOption title={"Composer"} onClick={() => {
                navigation("/")
             }}></YapContextMenuOption>
            <YapContextMenuDivider />
            <YapContextMenuOption title={"Logout"} icon={exitIcon} onClick={() => {
                navigation("/login");
             }}></YapContextMenuOption>
        </div>
    )
}