
import AST from './YapUIAST.js'

export function YapUIEncoder(js, options) {
    return parse(js, options)
}

export function YapUIComponentEncoder(name, js) {
    const jsyapui = YapUIEncoder(js);
    if (jsyapui && jsyapui.body) {
        // Create component
        return AST.ComponentDescription(name, {}, jsyapui.body, jsyapui.previews, jsyapui.props)
    } else {
        return null
    }
}


/**
 * Tree Encoder
 */
var componentAST = null
var variables = {}
var leaf = 0

// Proxy to handle arbitrary component names
export const $ = new Proxy(
    {},
    {
        get: function (target, prop, receiver) {
            return function () {
                switch (prop) {
                    // Variable
                    case 'Variable':
                        return Variable(...arguments)

                    // Conditional
                    case 'Conditional':
                        return Conditional(...arguments)

                    // ForEach
                    case 'ForEach':
                        return ForEach(...arguments)

                    case 'Defaults':
                        return Defaults(...arguments)
                    // Component 
                    default:
                        return Component(prop, ...arguments);
                }

            };
        },
    }
);

// Pretend to use this to make the minifier happy / know we're using
$.Init('init')

var componentName = null
function parse(string, options) {
    componentName = options?.componentName  

    // Pre-process components by adding '$.' to the beginning of the component name so the are called via the proxy.
    // Convert "MyComponent(...) to $.MyComponent(...)"
    string = string.replace(/(^|[^\w.])([A-Z][A-Za-z0-9_]+)\s*\(/g, (match, p1, p2) => {
        return `${p1}\$.${p2}(`;
    });

    // Replace variables in the string that start with '$' and are valid variable names
    // Variables can be dot-separated (e.g., $configuration.label) but should not have
    // method calls or modifiers (e.g., $number.or("99")) following the variable name.


    // Replace $VariableName with Variable('VariableName')
    string = string.replace(/\$(\w+)/g, (match, p1) => `Variable('${p1}')`);
    //string = replaceVariables(string)

    // Debug - output pre-processed version
    console.log(string)

    // Setup return component
    var component = { body: null, previews: [] }

    /**
     * Parse Body
     */
    // Use const body () => first
    const bodyFunction = parseASTFunction('body', string)
    if (bodyFunction) {

        component.body = (bodyFunction.length == 1) ? bodyFunction[0] : AST.Directive('Group', {}, bodyFunction)

    // Otherwise use main body
    } else {

        let mainBody = parseASTFunction(null, string)
        if (mainBody) {
            component.body = (mainBody.length == 1) ? mainBody[0] : AST.Directive('Group', {}, mainBody)
        }

    }

    /**
     * Parse Previews
     */
    const previewsFunction = parseASTFunction('previews', string) ?? []
    if (previewsFunction && previewsFunction.length > 0) {  
        component.previews = previewsFunction.map((preview, idx) => {              
            let data = { index: idx, body: preview }    

            let title = AST.findModifier(preview, 'previewName', true)?.props._0
            if (title) {
                data.title = title
            }

            return data
        })
    }

    /**
     * Parse Components
     */
    const componentFunction = parseJSONFunction('component', string)
    component.props = componentFunction ?? {}

    return component;
}

// function replaceVariables(input) {
//     // Step 1: Transform simple variables like $configuration
//     let result = input.replace(/\$(\w+)/g, "Variable('$1')");
  
//     // Step 2: Transform dot-notation variables without parentheses after the last dot
//     result = result.replace(/\$(\w+(?:\.\w+)+)(?!\.\w*\()/g, (match, p1) => {
//       return `Variable('${p1}')`;
//     });
  
//     // Step 3: Transform remaining dot-notation variables with method calls
//     result = result.replace(/\$(\w+(?:\.\w+)*)((?:\.\w+\([^)]*\))+)/g, (match, p1, p2) => {
//       return `Variable('${p1}')${p2}`;
//     });
  
//     return result;
// }

function Variable(key) {
    return makeAST(() => {
        const ast = AST.Variable(key);

        // Push into leafs children. If this variable is actually a property it will be removed after the closure has called.
        if (variables[leaf]) {
            variables[leaf].children.push(ast)
        }

        return ast
    }, { appendToParent: false }, { _variable: true })
}

function Conditional(condition, thenContent, elseContent) {
    return makeAST(() => {
        // Tag any variables
        var args = tagVariables([condition, thenContent, elseContent]);

        return AST.Conditional(parseArg(args[0]), parseArg(args[1]), parseArg(args[2]))
    })
}

function Defaults(variables, content) {
    return makeAST(() => {
        // Tag any variables
        var args = tagVariables([variables]);

        return AST.Defaults(parseArg(args[0]), parseArg(content))
    })
}

function ForEach(data, content) {
    return makeAST(() => {
        // Tag any variables
        var args = tagVariables([data, content]);

        return AST.ForEach(parseArg(args[0]), parseArg(args[1]))
    })
}

function Component(name) {
    return makeAST(() => {

        if (name == 'Self' && componentName != null) {
            name = componentName
        }
        
        // Create AST for component
        const ast = AST.Directive(name, {}, [])

        // Capture args, remove first argument as it's the component name.
        var args = Array.prototype.slice.call(arguments, 1);

        // Tag properties
        args = tagVariables(args);

        // Process each argument.
        args.forEach((arg, index) => {

            // test for now
            if (name == 'Color' && index == 0) {
                ast.props['_0'] = parseArg(arg)

            // Props - Dictionary at idx 0                
            } else if (index == 0 && typeof arg == 'object' && !Array.isArray(arg) && arg._variable == null) {
                ast.props = parseArg(arg)

                // Children - Single value or array
            } else {
                let val = parseArg(arg)
                if (Array.isArray(val)) {
                    ast.children.push(...val)
                } else {
                    ast.children.push(val)
                }
            }
        })

        return ast
    })
}


function Modifier(prevProxy, name, argument) {
    var props = {};

    // Tag 'Variable' as a property if exists 
    var argument = tagVariables([argument])[0]

    // Overlay, background etc.
    if (typeof argument == 'function') {
        console.log('function')
        const parentAST = componentAST;
        componentAST = { children: [] };
        argument();
        props = { _0: componentAST.children };
        componentAST = parentAST;
        // Dictionary or Array
    } else if (typeof argument == 'object') {
        // Proxy variable
        // if (argument.content && argument._variable) {
        //     props['_0'] = argument.content
        
        // Proxy element
        if (argument.content) {
            props['_0'] = argument.content;

        // Dictionary
        } else {
            props = argument
        }
        
    } else if (typeof argument == 'string' || typeof argument == 'number' || typeof arg === 'boolean') {
        props['_0'] = argument;
    } else {
        console.warn(
            `Modifier ${name} received an argument of unsupported type.`
        );
    }

    // Get parent info
    const { parentAST, indexInParent } = prevProxy

    var ast = {}

    if (parentAST && indexInParent !== undefined) {
        const modifiedContent = parentAST.children[indexInParent];

        /**
         * Map modifier types.
         */
        switch (name) {
            case 'defaults':
                ast = AST.Defaults(props, modifiedContent)
                break;
            case 'or':
                ast = AST.Binary('??', modifiedContent, props['_0'])
                break;
            default:
                const modifier = AST.Directive(name, props, []);
                ast = AST.ModifiedContent(modifier, modifiedContent)
                break;
        }

        parentAST.children[indexInParent] = ast;
    } else {
        console.warn('No parentAST')
    }

    const modifierProxy = {
        content: ast,
        parentAST: parentAST,
        indexInParent: indexInParent,
    };

    return new Proxy(modifierProxy, {
        get(target, prop, receiver) {
            if (prop in target) return target[prop];

            // Assume any other property is a modifier
            return (...modifierArgs) => Modifier(target, prop, ...modifierArgs);
        },
    });
}

function removeVariables(children) {
    if (children == null) { return null }

    // Remove any children that were intended as properties for a component in the closure
    return children.filter(child => {
        if (child._isProperty) {
            delete child._isProperty;
            return false;
        } else {
            return true;
        }
    });
}

function parseArg(arg, ast) {

    const resolveValues = (value) => {    
        if (value == null) {
            return value
        } else if (typeof value == 'string') {
            return value
        } else if (typeof value == 'object' && !Array.isArray(value) && value.content) {
            return value.content
        } else if (typeof value == 'object' && Array.isArray(value)) {
            return value.map((child) => {
                return resolveValues(child)
            })
        } else if (typeof value == 'object' && !Array.isArray(value)) {
            var props = value
            for (const key in props) {
                let keyValue = props[key]
                props[key] = resolveValues(keyValue)
            }
            return props
        } else {
            return value
        }
    }

    // Node children
    if (typeof arg == 'function') {

        // Reset variable capture

        // Asset current ast to write into
        componentAST = { children: [] }

        // Create index for variables to write into
        variables[++leaf] = componentAST

        // Call the closure
        arg()

        // Remove any children that were intended as properties for a component in the closure
        const finalChildren = removeVariables(componentAST.children)

        leaf--;

        // Push final children
        return [...finalChildren]
    } else {
        return resolveValues(arg)
    }

}

function makeAST(callback, options = {}, additionalProps = {}) {
    // Capture current parent AST
    const parentAST = componentAST

    // Call the content callback.
    const ast = callback()

    var componentProxy = {}

    var indexInParent = null;
    if (parentAST && parentAST.children && Array.isArray(parentAST.children)) {

        if (options.appendToParent != false) {
            parentAST.children.push(ast)
        }

        indexInParent = parentAST.children.length - 1;
    }

    // Return a proxy to handle modifiers
    componentProxy = {
        content: ast,
        indexInParent: indexInParent,
        parentAST: parentAST,
    };

    componentAST = parentAST

    return new Proxy(componentProxy, {
        get(target, prop, receiver) {
            if (prop in target) return target[prop];

            // Assume any other property is a modifier
            return (...modifierArgs) => Modifier(target, prop, ...modifierArgs);
        },
    });
}


/**
* Parses a function that returns a JSON object
* @returns json object
*/
const parseASTFunction = (functionName, code) => {
    if (code == null || code.length == 0) {
        return []
    }

    /** Parse previews */
    try {

        // Clear ast 
        const emptyAST = AST.Directive('Group', {}, [])

        // track variables
        variables = {}
        variables[++leaf] = emptyAST

        var returnValue = null;

        componentAST = functionName ? null : emptyAST

        // Construct call
        var call = code

        if (functionName) {
            call +=
        `
            variables = {};
            componentAST = emptyAST;

            ${functionName}();      
        `
        }

        call += 
        `
            returnValue = componentAST.children   ;
        `

        eval(call)

        componentAST = null

        // Remove any children that were intended as properties for a component in the closure
        returnValue = removeVariables(returnValue)

        return returnValue

    } catch (e) {
        console.error('parseASTFunction: error executing', e);
        return null
    }
}

/**
 * Parses a function that returns a JSON object
 * @returns json object
 */
const parseJSONFunction = (functionName, code) => {
    try {
        var returnValue = null

        // Construct call
        const call = code +
            `
                returnValue = ${functionName}();
            `

        eval(call)

        return returnValue
    } catch (e) {
        console.warn('parseJSONFunction: error executing:', e);
        return null
    }
}

/**
 * Tags any objects that are 'Variables' as being part of the property list.
 * Due to the order of execution in the parsing these need to be tagged so they can be removed from the children list. 
 */
function tagVariables (processArgs)  {
    return processArgs.map(arg => {
        // Any Variables that are passsed as arguments, mark as a property so they can be correctly added to the correct node.
        // Is is that because variables get executed first, we need to inspect after executing the components contents.
        if (arg && typeof arg === 'object') {
            if (arg.content) {
                arg.content._isProperty = true;
            } else if (Array.isArray(arg)) {
                arg = arg.map(child => tagVariables([child])[0]);
            } else {
                for (const key in arg) {
                    let value = arg[key]

                    // Proxy value
                    if (value?.content) {
                        arg[key].content._isProperty = true;
                    } else if (value?._variable) {
                        arg[key]._isProperty = true;
                    } else {
                        arg[key] = tagVariables([value])[0];
                    }
                }
            }
        }
        return arg
    })
}
