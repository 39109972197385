/**
 * Template for a new file
 */
export const newFileTemplate = ({ name }) => { return `const component = () => {
    return {
        'name'        : '${name}',
        'version'     : '1.0.0',
        'author'      : null,
        'description' : null
    }
}

const body = () => {
    Text("Hello World")
}
        
const previews = () => {
    ${name}()
}
`
}