const AST = {} 

AST.Directive = (name, args, children) => {
    return {
        'type': name,
        'props': args ?? {},
        'children': children ?? []
    }
}

AST.ModifiedContent = (modifier, component) => {
    var mod = {...modifier}
    if (Array.isArray(component)) {
        mod.children = component
    } else {
        mod.children = [component]
    }
    return mod
}

AST.Variable = (key) => {
    return {
        'type' : 'Variable',
        'name' : key
    }
}

AST.ComponentDescription = (name, parameters, body, previews, props) => { 
    return {
        'type' : 'ComponentDescription',
        'name' : name,
        'props' : props,        
        'parameters' : parameters,
        'body' : body,
        'previews' : previews,
    }
}

AST.Defaults = (variables, content) => {
    return {
        'type' : 'defaults',
        'variables' : variables,
        'content' : content
    }
}

AST.Conditional = (condition, thenContent, elseContent) => {
    return {
        'type' : 'Conditional',
        'condition' : condition,
        'then' : thenContent,
        'else' : elseContent,
    }
}

AST.ForEach = (data, content)  => {
    return {
        'type' : 'ForEach',
        'data' : data,
        'content' : content
    }
}

AST.Binary = (operator, lhs, rhs) => {
    return {
        'type' : 'Binary',
        'operator' : operator,
        'left' : lhs,
        'right' : rhs,
    }
}

AST.findModifier = (json, modifierName, deep) => {
    let result = null;

    // Helper function to recursively traverse the JSON structure
    const traverse = (node) => {

        if (typeof node !== 'object' || node === null) {
            return;
        }

        let isModifier = node.type && /^[a-z]/.test(node.type)
        if (!isModifier) {
            if (!deep) {
                return;                
            }
        }

        // Check if the current node's type exists, has a lowercase first character, and matches the modifier name
        if (node.type === modifierName) {
            result = node;
            return;
        }

        // If the node has children, traverse through them
        if (Array.isArray(node.children)) {
            for (const child of node.children) {
                traverse(child);
                if (result) {
                    return; // If we found the modifier, stop further traversal
                }
            }
        }
    };

    traverse(json);

    return result;
};

AST.findComponent = (json) => {
    if (json && String(json.type).toLowerCase() == 'componentdescription') {
        return json
    } else {
        return null
    }
}

export default AST