import React, { createContext, useContext } from 'react';

// Define a type for the Asset object
export interface Asset {
    id: string;
    alt: string;
    name: string;
    url: string;
    dimensions?: { width: number, height: number }; 
}

// Define a type for the style context
export interface AssetsContextType {
    get: (key: string, componentName: string) => Asset | null;
    name: string;
}

// Create the AssetsContext with a default value
export const AssetsContext = createContext<AssetsContextType>({
    get: (key: string, componentName: string): Asset | null => null,
    name: '',
});

// Custom hook to use the AssetsContext
export function useAssets() {
    let context = useContext(AssetsContext);
    return { get: (key: string) => {
        if (context.get) {
            return context.get(key, context.name);
        } else {
            return null
        }
    }}
}
