import React, { useState, useEffect } from 'react';
import { useStyle, useForegroundStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';

export function Circle() {
    const actions = useActions()
    const foregroundStyle = useForegroundStyle();

    const style = {
        ...useStyle(),
        borderRadius: '50%',
        aspectRatio: '1',
        flexGrow: 1,
        backgroundColor: foregroundStyle.color ?? 'black',
    };

    // Ensure width and height fill available space if not defined
    // if (!style.width) {
    //     style.width = '-webkit-fill-content';
    // }
    // if (!style.height) {
    //     style.height = '-webkit-fill-content';
    // }

    return <div style={style} {...actions}></div>;
}