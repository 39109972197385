
export function parseSwiftUI(swiftui) {
    let tokens = new Lexer(swiftui).tokenize()
    console.log(tokens.map (t => t.defaultText))    
    let parser = new Parser(tokens);
    return parser.parse();
}

class Parser {
    constructor(tokens) {
        this.tokens = tokens;
        this.consumed = [];
    }

    parse() {
        let accumulator = [];
        while (!this.isAtEnd()) {
            let decl = this.declaration();
            if (!(decl instanceof Empty)) {
                accumulator.push(decl);
            }
            this.eatNewlines();
        }
        switch (accumulator.length) {
            case 0: return new Empty();
            case 1: return accumulator[0];
            default: return accumulator;
        }
    }

    declaration() {
        try {
            if (this.match({ type: 'var' })) {
                return this.varDecl();
            } else {
                return this.statement();
            }
        } catch (error) {
            console.error(error);
            this.synchronize();
            return new Empty();
        }
    }

    varDecl() {
        const identifier = this.identifier();
        let initializer = null;
        if (this.match({ type: 'operator', value: '=' })) {
            initializer = this.expression();
        }
        return new VariableDeclaration(identifier.name, initializer || new Empty());
    }

    statement() {
        if (this.match({ type: 'for' })) {
            return this.forStatement();
        } else if (this.match({ type: 'if' })) {
            return this.ifStatement();
        } else if (this.check({ type: 'leftBrace' })) {
            return this.block();
        } else {
            return this.expression();
        }
    }

    expression() {
        const expression = this.assignment();
        this.eatNewlines();
        return expression;
    }

    identifier() {
        const token = this.consume({ type: 'identifier' }, "Expected identifier");
        if (token) {
            return new Variable(token.value);
        }
    }

    ifStatement() {
        const condition = this.expression();
        const trueContent = this.block();
        let falseContent = null;
        if (this.match({ type: 'else' })) {
            falseContent = this.block();
        }
        return new Conditional(condition, trueContent, falseContent);
    }

    forStatement() {
        const data = this.expression();
        const content = this.block();
        return new ForEach(data, content);
    }

    assignment() {
        return this.or();
    }

    binaryExpression(operators, higherPrecedence) {
        let lhs = higherPrecedence.call(this);
        
        while (this.match(...operators.map(op => ({ type: 'operator', value: op })))) {
            const op = this.previous().value;
            const rhs = higherPrecedence.call(this);
            lhs = new Binary(lhs, op, rhs);
        }
        
        return lhs;
    }

    or() {
        return this.binaryExpression(['||'], () => this.and());
    }

    and() {
        return this.binaryExpression(['&&'], () => this.equality());
    }

    equality() {
        return this.binaryExpression(['!=', '=='], () => this.comparison());
    }

    comparison() {
        return this.binaryExpression(['>', '>=', '<', '<='], () => this.additionSubtraction());
    }

    additionSubtraction() {
        return this.binaryExpression(['+', '-'], () => this.multiplicationDivision());
    }

    multiplicationDivision() {
        return this.binaryExpression(['*', '/'], () => this.unary());
    }

    unary() {
        if (this.match({ type: 'operator', value: '!' }, { type: 'operator', value: '-' })) {
            const op = this.previous().value;
            return new Binary(new Empty(), op, this.unary());
        }
        return this.call();
    }

    call() {
        let expr = this.primary();
        while (true) {
            if (this.previous().type === 'identifier' && this.match({ type: 'leftParen' })) {
                expr = this.finishCallExpression(expr);
            } else if (this.match({ type: 'period' })) {
                expr = this.finishMemberExpression(expr);
            } else {
                break;
            }
        }
        return expr;
    }

    finishCallExpression(callee) {
        const args = this.argumentList();
        if (callee instanceof Variable) {
            let children = [];
            if (this.match({ type: 'leftBrace' })) {
                children = this.expressionList();
                this.consume({ type: 'rightBrace' }, "Expected '}' after expression");
            }
            return new Directive(callee.name, args, children);
        } else if (callee instanceof Directive) {
            return new Directive(callee.type, args, callee.children);
        } else {
            return new Binary(callee, '()', args);
        }
    }

    finishMemberExpression(callee) {
        const identifier = this.identifier();
        if (callee instanceof Directive) {
            return new Directive(identifier.name, {}, [callee]);
        } else {
            return new Binary(callee, '.', identifier);
        }
    }

    block() {
        this.consume({ type: 'leftBrace' }, "Expected '{' after expression");
        const body = this.expressionList();
        this.consume({ type: 'rightBrace' }, "Expected '}' after expression");
        return new Closure(body);
    }

    expressionList() {
        const accumulator = [];
        while (!this.check({ type: 'rightBrace' }) && !this.isAtEnd()) {
            this.eatNewlines();
            accumulator.push(this.expression());
        }
        return accumulator;
    }

    eatNewlines() {
        while (this.peek() && this.peek().type === 'newline') {
            this.advance();
        }
    }

    primary() {
        if (this.match({ type: 'leftParen' })) {
            return this.grouping();
        } else if (this.check({ type: 'leftBrace' })) {
            return this.block();
        } else {
            return this.literal();
        }
    }

    grouping() {
        const expression = this.expression();
        this.consume({ type: 'rightParen' }, "Expected ')' after expression");
        return expression;
    }

    argumentList() {
        const accumulator = {};
        let index = 0;
        while (!this.check({ type: 'rightParen' }) && !this.isAtEnd()) {
            let key, value;
            
            if (this.peek().type === 'identifier' && this.peekNext().type === 'colon') {
                const keyToken = this.consume({ type: 'identifier' }, "Expected identifier");
                this.consume({ type: 'colon' }, "Expected ':' after key");
                value = this.expression();
                key = keyToken.value;
            } else {
                value = this.expression();
                key = `_${index}`;
                index++;
            }
            
            accumulator[key] = value;
            
            if (!this.match({ type: 'comma' })) {
                break;
            }
        }
        this.consume({ type: 'rightParen' }, "Expected ')' after arguments");
        return accumulator;
    }

    literal() {
        if (this.match({ type: 'string' })) {
            return this.previous().value;
        } else if (this.match({ type: 'integer' })) {
            return parseInt(this.previous().value, 10);
        } else if (this.match({ type: 'double' })) {
            return parseFloat(this.previous().value);
        } else if (this.match({ type: 'boolean' })) {
            return this.previous().value === 'true';
        } else if (this.check({ type: 'identifier' })) {
            const variable = this.identifier();
            if (this.match({ type: 'leftBrace' })) {
                const list = this.expressionList();
                this.consume({ type: 'rightBrace' }, "Expected '}' after expression");
                return new Directive(variable ? variable.name : '', {}, list);
            } else {
                return variable;
            }
        }
        throw new Error("Expected expression");
    }

    isAtEnd() {
        return this.tokens.length === 0;
    }

    previous() {
        return this.consumed[this.consumed.length - 1];
    }

    peek() {
        return this.tokens[0];
    }

    peekNext() {
        return this.tokens.length > 1 ? this.tokens[1] : null;
    }

    advance() {
        this.consumed.push(this.tokens.shift());
    }

    check(expectedToken) {
        this.eatNewlines();
        if (!this.peek()) return false;
        return this.tokenMatches(this.peek(), expectedToken);
    }

    match(...expectedTokens) {
        this.eatNewlines();
        if (!this.peek()) return false;
        for (const expectedToken of expectedTokens) {
            if (this.tokenMatches(this.peek(), expectedToken)) {
                this.advance();
                return true;
            }
        }
        return false;
    }

    consume(expectedToken, message) {
        this.eatNewlines();
        if (!this.peek()) {
            throw new Error("Unexpected end of file");
        }
        if (this.tokenMatches(this.peek(), expectedToken)) {
            return this.advance();
        }
        throw new Error(message);
    }

    synchronize() {
        while (!this.isAtEnd()) {
            if (this.peek().type === 'newline') {
                return;
            }
            switch (this.peek().type) {
                case 'for':
                case 'if':
                case 'leftBrace':
                    return;
                default:
                    this.advance();
            }
        }
    }

    tokenMatches(token, expectedToken) {
        for (const key in expectedToken) {
            if (token[key] !== expectedToken[key]) {
                return false;
            }
        }
        return true;
    }
}


// Token class to represent different token types
class Token {
    constructor(type, value = null) {
        this.type = type;
        this.value = value;
    }

    get defaultText() {
        switch (this.type) {
            case 'identifier':
            case 'string':
            case 'double':
            case 'integer':
            case 'boolean':
            case 'operator':
                return String(this.value);
            case 'var':
            case 'for':
            case 'in':
            case 'if':
            case 'else':
                return this.type;
            case 'leftParen':
                return '(';
            case 'rightParen':
                return ')';
            case 'leftBrace':
                return '{';
            case 'rightBrace':
                return '}';
            case 'comma':
                return ',';
            case 'colon':
                return ':';
            case 'period':
                return '.';
            case 'newline':
                return '\n'.repeat(this.value);
            case 'unknown':
                return String(this.value);
            default:
                return '';
        }
    }

    static matches(token1, token2) {
        if (token1.type !== token2.type) return false;
        switch (token1.type) {
            case 'identifier':
            case 'string':
            case 'double':
            case 'integer':
            case 'boolean':
            case 'newline':
                return true;
            case 'operator':
                return token1.value === token2.value;
            default:
                return token1.type === token2.type;
        }
    }
}

class Lexer {
    constructor(input) {
        this.input = input;
        this.index = 0;
    }

    get isAtEnd() {
        return this.index >= this.input.length;
    }

    peek() {
        return this.isAtEnd ? null : this.input[this.index];
    }

    advance() {
        return this.input[this.index++];
    }

    tokenize() {
        const tokens = [];

        while (!this.isAtEnd) {
            const char = this.peek();
            switch (char) {
                case ' ':
                case '\t':
                    this.advance();
                    break;
                case '\n':
                case '\r':
                    tokens.push(this.tokenizeNewline());
                    break;
                case 'a':
                case 'b':
                case 'c':
                case 'd':
                case 'e':
                case 'f':
                case 'g':
                case 'h':
                case 'i':
                case 'j':
                case 'k':
                case 'l':
                case 'm':
                case 'n':
                case 'o':
                case 'p':
                case 'q':
                case 'r':
                case 's':
                case 't':
                case 'u':
                case 'v':
                case 'w':
                case 'x':
                case 'y':
                case 'z':
                case 'A':
                case 'B':
                case 'C':
                case 'D':
                case 'E':
                case 'F':
                case 'G':
                case 'H':
                case 'I':
                case 'J':
                case 'K':
                case 'L':
                case 'M':
                case 'N':
                case 'O':
                case 'P':
                case 'Q':
                case 'R':
                case 'S':
                case 'T':
                case 'U':
                case 'V':
                case 'W':
                case 'X':
                case 'Y':
                case 'Z':
                case '_':
                    tokens.push(this.tokenizeIdentifier());
                    break;
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    tokens.push(this.tokenizeNumber());
                    break;
                case '"':
                    tokens.push(this.tokenizeString());
                    break;
                case '+':
                case '-':
                case '*':
                case '/':
                    tokens.push(new Token('operator', this.advance()));
                    break;
                case '=':
                    this.advance();
                    if (this.peek() === '=') {
                        tokens.push(new Token('operator', '=='));
                        this.advance();
                    } else {
                        tokens.push(new Token('operator', '='));
                    }
                    break;
                case '!':
                    this.advance();
                    if (this.peek() === '=') {
                        tokens.push(new Token('operator', '!='));
                        this.advance();
                    } else {
                        tokens.push(new Token('operator', '!'));
                    }
                    break;
                case '<':
                    this.advance();
                    if (this.peek() === '=') {
                        tokens.push(new Token('operator', '<='));
                        this.advance();
                    } else {
                        tokens.push(new Token('operator', '<'));
                    }
                    break;
                case '>':
                    this.advance();
                    if (this.peek() === '=') {
                        tokens.push(new Token('operator', '>='));
                        this.advance();
                    } else {
                        tokens.push(new Token('operator', '>'));
                    }
                    break;
                case '{':
                    tokens.push(new Token('leftBrace'));
                    this.advance();
                    break;
                case '}':
                    tokens.push(new Token('rightBrace'));
                    this.advance();
                    break;
                case '(':
                    tokens.push(new Token('leftParen'));
                    this.advance();
                    break;
                case ')':
                    tokens.push(new Token('rightParen'));
                    this.advance();
                    break;
                case ',':
                    tokens.push(new Token('comma'));
                    this.advance();
                    break;
                case ':':
                    tokens.push(new Token('colon'));
                    this.advance();
                    break;
                case '.':
                    tokens.push(new Token('period'));
                    this.advance();
                    break;
                default:
                    tokens.push(new Token('unknown', this.advance()));
            }
        }
        return tokens;
    }

    tokenizeNewline() {
        let count = 0;
        while (this.peek() === '\n' || this.peek() === '\r') {
            count++;
            this.advance();
        }
        return new Token('newline', count);
    }

    tokenizeIdentifier() {
        let identifier = '';
        while (this.peek() && /[a-zA-Z0-9_]/.test(this.peek())) {
            identifier += this.advance();
        }
        
        switch (identifier) {
            case 'for':
                return new Token('for');
            case 'in':
                return new Token('in');
            case 'if':
                return new Token('if');
            case 'else':
                return new Token('else');
            case 'true':
                return new Token('boolean', true);
            case 'false':
                return new Token('boolean', false);
            case 'var':
                return new Token('var');
            default:
                return new Token('identifier', identifier);
        }
    }

    tokenizeNumber() {
        let numberString = '';
        let hasDecimalPoint = false;
        let hasExponent = false;

        while (this.peek()) {
            const char = this.peek();
            if (/[0-9]/.test(char)) {
                numberString += this.advance();
            } else if (char === '.' && !hasDecimalPoint && !hasExponent) {
                hasDecimalPoint = true;
                numberString += this.advance();
            } else if ((char === 'e' || char === 'E') && !hasExponent) {
                hasExponent = true;
                numberString += this.advance();

                // Handle optional '+' or '-' after exponent
                if (this.peek() === '+' || this.peek() === '-') {
                    numberString += this.advance();
                }
            } else {
                break;
            }
        }

        if (!hasDecimalPoint && !hasExponent) {
            return new Token('integer', parseInt(numberString, 10));
        } else {
            return new Token('double', parseFloat(numberString));
        }
    }

    tokenizeString() {
        // Consume the opening quote
        this.advance();
        let stringContent = '';

        while (this.peek()) {
            const char = this.peek();
            if (char === '"') {
                // Closing quote found
                this.advance();
                break;
            } else if (char === '\\') {
                // Handle escape sequences
                this.advance();
                const escapedChar = this.peek();
                if (escapedChar) {
                    switch (escapedChar) {
                        case '"':
                            stringContent += '"';
                            this.advance();
                            break;
                        case '\\':
                            stringContent += '\\';
                            this.advance();
                            break;
                        case 'n':
                            stringContent += '\n';
                            this.advance();
                            break;
                        case 't':
                            stringContent += '\t';
                            this.advance();
                            break;
                        // Add more escape sequences as needed
                        default:
                            // Unknown escape sequence, include both characters
                            stringContent += '\\' + this.advance();
                    }
                } else {
                    // Unterminated escape sequence
                    stringContent += '\\';
                    break;
                }
            } else {
                stringContent += this.advance();
            }
        }
        return new Token('string', stringContent);
    }
}

// Base Component class
class Component {
    modifier(type, props = {}) {
        return new Directive(type, props, [this]);
    }

    defaults(key, value) {
        return new Defaults({ [key]: value }, this);
    }

    replacingEmpty(other) {
        return new Binary(this, "??", other);
    }

    get(key) {
        return new Binary(this, ".", key);
    }

    isEqual(other) {
        return new Binary(this, "==", other);
    }
}

// Primitive types as components
// String.prototype.__proto__ = Component.prototype;
// Number.prototype.__proto__ = Component.prototype;
// Boolean.prototype.__proto__ = Component.prototype;

class Empty extends Component {
    constructor() {
        super();
    }
}

// Arrays and Objects as components
// Array.prototype.__proto__ = Component.prototype;
// Object.prototype.__proto__ = Component.prototype;

class Directive extends Component {
    constructor(type, props = {}, children = []) {
        super();
        this.type = type;
        this.props = props;
        this.children = children;
    }
}

class VariableDeclaration extends Component {
    constructor(name, value) {
        super();
        this.name = name;
        this.value = value;
    }
}

class Closure extends Component {
    constructor(content) {
        super();
        this.content = content;
    }
}

class Conditional extends Component {
    constructor(condition, trueContent, falseContent = null) {
        super();
        this.condition = condition;
        this.trueContent = trueContent;
        this.falseContent = falseContent;
    }
}

class ForEach extends Component {
    constructor(data, content) {
        super();
        this.data = data;
        this.content = content;
    }
}

class Binary extends Component {
    constructor(left, op, right) {
        super();
        this.left = left;
        this.op = op;
        this.right = right;
    }
}

class Defaults extends Component {
    constructor(variables, content) {
        super();
        this.variables = variables;
        this.content = content;
    }
}

class Variable extends Component {
    constructor(name, ...path) {
        super();
        this.name = name;
        this.path = path;
    }

    static get children() { return new Variable("children"); }
    static get index() { return new Variable("index"); }
    static get element() { return new Variable("element"); }
}

class AnyComponent extends Component {
    constructor(component) {
        super();
        if (component instanceof AnyComponent) {
            return component;
        } else {
            this.component = component;
        }
    }

    static fromJSON(jsonString) {
        const jsonObject = JSON.parse(jsonString);
        return new AnyComponent(makeComponent(jsonObject));
    }

    static fromObject(jsonObject) {
        return new AnyComponent(makeComponent(jsonObject));
    }
}

// Helper function to create components from JSON objects
function makeComponent(obj) {
    if (typeof obj === 'string') return obj;
    if (typeof obj === 'number') return obj;
    if (typeof obj === 'boolean') return obj;
    if (Array.isArray(obj)) return obj.map(makeComponent);
    if (typeof obj === 'object') {
        if (obj.type === 'Directive') {
            return new Directive(obj.directiveType, makeComponent(obj.props), makeComponent(obj.children));
        }
        // Add more component types as needed
        return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, makeComponent(v)]));
    }
    return new Empty();
}

class ComponentDescription extends Component {
    constructor(name, component = {}, parameters = {}, body, previews = []) {
        super();
        this.name = name;
        this.component = component;
        this.parameters = parameters;
        this.body = body;
        this.previews = previews;
    }
}
