import React, { useState, useEffect } from 'react'

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDoc, query, deleteDoc, getDocs, addDoc,onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, } from "firebase/storage";
import { v4 as uuid } from 'uuid';
import { useSessionStorage } from 'usehooks-ts'
import { useAuth } from './useAuth';

/*
 * --------------
 * Firebase Setup
 * --------------
 */
// TODO: Replace the following with your app's Firebase project configuration
// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyBhOSQVlVhXXQlLHu_PMVmHcpWsVfDc3uI",
    authDomain: "content-builder-aa1f5.firebaseapp.com",
    projectId: "content-builder-aa1f5",
    storageBucket: "content-builder-aa1f5.appspot.com",
    messagingSenderId: "408747115076",
    appId: "1:408747115076:ios:1fd609aa238f40a9abf211"
};

export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Local data store
var assetDataStore = {}

// Create a root reference
const storage = getStorage();

//
// Identifiers to use to hang all the data off. 
// Changing these will create a new repoistory for assets and documents
//
// institutionId       = documents/messages
// assetsInstitutionId = assets (images, videos)
//
var institutionId = null
var assetsInstitutionId = null
var instanceId = null

const initBucketData = () => {
    console.log('- initBucketData')
    
    const [bucketData, setBucketData] = useSessionStorage("bucketData", null);

    console.log(bucketData)

    if (bucketData == null) { return }

    institutionId = bucketData.institutionId    
    instanceId = bucketData.instance

    if (bucketData.assetsInstitutionId) {
        assetsInstitutionId = bucketData.assetsInstitutionId
    } else {
        assetsInstitutionId = institutionId
    }

    return bucketData
}

const currentInstanceId = () => {
    return instanceId
}

/**
 * --------------
 * Asset Updating 
 * --------------
 */
const updateAsset = (asset) => {
    const docRef = doc(db, "assets", assetsInstitutionId);

    let data = {}
    data[asset.id] = asset
    setDoc(docRef, data, { merge: true });

    assetDataStore[asset.id] = { ...asset }
    return assetDataStore
}

const deleteAsset = (asset) => {
    const docRef = doc(db, "assets", assetsInstitutionId);

    delete assetDataStore[asset.id]
    setDoc(docRef, assetDataStore, { merge: false });

    return assetDataStore
}

const loadAssets = async () => {
    const docRef = doc(db, "assets", assetsInstitutionId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let data = docSnap.data()
        assetDataStore = data
        return data
    } else {
        console.log("No such document!");
        return {}
    }
}

/**
 * Types
 */
const loadTypes = async (appInstanceId) => {
    const docRef = doc(db, "types", (appInstanceId ?? institutionId));
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let data = docSnap.data()
        return data.types
    } else {
        console.log("No such document!");
        return []
    }
}

const updateTypes = (types) => {
    const docRef = doc(db, "types", institutionId);
    setDoc(docRef, { types: types } , { merge: false });
    return types
}

// Custom hook to subscribe to real-time Firestore updates
const useTypes = (appInstanceId) => {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true); // To track loading state
    const [error, setError] = useState(null); // To track errors

    useEffect(() => {
        if (!appInstanceId) {
            setError('Invalid app instance ID');
            setLoading(false);
            return;
        }

        const docRef = doc(db, 'types', appInstanceId);

        // Subscribe to real-time updates using onSnapshot
        const unsubscribe = onSnapshot(
            docRef,
            (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setTypes(data.types || []);
                } else {
                    console.log('No such document!');
                    setTypes([]);
                }
                setLoading(false); // Stop loading once the document has been processed
            },
            (error) => {
                console.log('Error fetching document:', error);
                setError('Failed to fetch document');
                setTypes([]);
                setLoading(false);
            }
        );

        // Clean up subscription when the component unmounts
        return () => {
            unsubscribe();
        };
    }, [appInstanceId]);

    return { types, loading, error };
};

// Custom hook to subscribe to real-time Firestore updates with initial load compatibility for `await`
const useTypesAsync = async (appInstanceId) => {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true); // To track loading state
    const [error, setError] = useState(null); // To track errors

    useEffect(() => {
        let unsubscribe = () => {}; // Initialize unsubscribe function

        const fetchInitialData = async () => {
            if (!appInstanceId) {
                setError('Invalid app instance ID');
                setLoading(false);
                return;
            }

            try {
                const docRef = doc(db, 'types', appInstanceId);

                // Fetch the initial document data using async/await
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setTypes(data.types || []);
                } else {
                    console.log('No such document!');
                    setTypes([]);
                }

                // Set loading to false after fetching initial data
                setLoading(false);

                // Set up the real-time subscription after the initial fetch
                unsubscribe = onSnapshot(docRef, (docSnap) => {
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setTypes(data.types || []);
                    } else {
                        console.log('No such document!');
                        setTypes([]);
                    }
                }, (error) => {
                    console.log('Error in real-time updates:', error);
                    setError('Failed to subscribe to real-time updates');
                });
            } catch (err) {
                console.log('Error fetching document:', err);
                setError('Failed to fetch initial document');
                setLoading(false);
            }
        };

        // Call the async function
        fetchInitialData();

        // Clean up subscription when the component unmounts
        return () => unsubscribe();
    }, [appInstanceId]);

    return { types, loading, error };
};

/**
 * -------
 * Login
 * ----
 */
const validateCode = async (code) => {
    const pageDoc = doc(db, `authCodes`, code);
    const documentSnapshot = await getDoc(pageDoc);
    if (documentSnapshot.exists()) {
        let data = documentSnapshot.data()
        return data
    } else {
        console.log("No such document!");
        return {}
    }
}

const loadAppInstance = async (appInstanceId) => {
    instanceId = appInstanceId
    institutionId = appInstanceId
    return {
        instanceId: appInstanceId,
        institutionId: appInstanceId    
    }
}

const loadConfig = async (data) => {
    var id = (data ?? {}).institutionId ?? institutionId
    if (id == null) { 
        return {}
    }

    const docRef = doc(db, 'config', id);
    const documentSnapshot = await getDoc(docRef);
    if (documentSnapshot.exists()) {
        let data = documentSnapshot.data()
        return data
    } else {
        console.log("No such document!");
        return { }
    }
}

/**
 * --------------
 * Page Updating 
 * --------------
 */
const loadDocuments = async () => {
    if (institutionId == null) { 
        console.log('no id')
        return [] 
    } else {
        console.log('id ' + institutionId)
    }

    const pagesCollection = collection(db, `content/${institutionId}/documents`);

    const querySnapshot = await getDocs(pagesCollection)

    var docData = []
    querySnapshot.forEach(doc => {
        docData.push(doc.data())
    })

    return docData
}

const loadDocument = async (pageId) => {
    const pageDoc = doc(db, `content/${institutionId}/documents`, pageId);
    const documentSnapshot = await getDoc(pageDoc);

    if (documentSnapshot.exists()) {
        let data = documentSnapshot.data()
        return data
    } else {
        console.log("No such document!");
        return null
    }
}

const updateDocument = (id, documentData) => {
    const docRef = doc(db, `content/${institutionId}/documents`, id);

    var data = { ...documentData }
    removeNullValues(data)

    data['id'] = id
    data['updated'] = new Date()
    if (data['created'] == null) {
        data['created'] = new Date()
    }

    setDoc(docRef, data, { merge: false });
    return assetDataStore
}

const deleteDocument = async (docId) => {
    const pageDoc = doc(db, `content/${institutionId}/documents`, docId);
    await deleteDoc(pageDoc);
}

const duplicateDocument = async (docId) => {
    const pageDoc = doc(db, `content/${institutionId}/documents`, docId);

    const documentSnapshot = await getDoc(pageDoc);

    if (documentSnapshot.exists()) {
        var data = { ...documentSnapshot.data() }
        console.log(data)
        //const newPageDoc = await addDoc(collection(db, `content/${assetsInstitutionId}/documents`), {  });
        //alert(newPageDoc)

        let id = uuid()
        data['id'] = id
        data['updated'] = new Date()
        data['created'] = new Date()

        const docRef = doc(db, `content/${institutionId}/documents`, id);
        await setDoc(docRef, data, { merge: false });
    } else {
        console.log("No such document!");
    }

}

function removeNullValues(obj) {
    if (Array.isArray(obj)) {
        for (let i = obj.length - 1; i >= 0; i--) {
            if (obj[i] == null || typeof obj[i] == 'function') {
                obj.splice(i, 1);
            } else if (typeof obj[i] === 'object') {
                removeNullValues(obj[i]);
            }
        }
    } else if (typeof obj === 'object') {
        for (const key in obj) {
            if (obj[key] == null || typeof obj[key] == 'function') {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                removeNullValues(obj[key]);
            }
        }
    }
}

/**
 * Asset Uploader Helper
 */
const fileUploader = ({ file, asset, progressCallback, completeCallback, errorCallback, setAssets }) => {

    // Create a reference to 'mountains.jpg'
    const fileRef = ref(storage, 'assets/' + assetsInstitutionId + '-' + asset.id + '-' + file.name);
    const uploadTask = uploadBytesResumable(fileRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed',
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            console.log('bytesTransferred ' + snapshot.bytesTransferred)
            console.log('totalBytes ' + snapshot.totalBytes)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes);

            // Update download UI
            progressCallback(progress);

            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        },
        (error) => {
            const message = error.code == 'storage/canceled' ? null : error.message
            errorCallback(message);
        },
        () => {

            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                // Create a new asset with:
                // - The metadata from the asset from the builder
                // - The new location
                // - A new id
                var newAsset = {
                    ...asset,
                    src: downloadURL,
                    id: uuid()
                }

                // Update assets with provided callback
                if (setAssets) {
                    setAssets({ ...updateAsset(newAsset, assetsInstitutionId) });
                }

                // Tell the asset uploader that upload is complete
                completeCallback(newAsset);
            });
        }
    );

    return uploadTask
}


export { initBucketData, validateCode, loadAppInstance, fileUploader, updateAsset, deleteAsset, loadAssets, loadConfig, currentInstanceId, assetsInstitutionId, loadTypes, useTypes,useTypesAsync, updateTypes, loadDocuments, updateDocument, loadDocument, duplicateDocument, deleteDocument }