import React, { useEffect } from 'react';
import styled from 'styled-components'
import { YapUIGenerator} from '@yapstudios/yap-content-builder';
import { resolveUnresolvedNodes, rewriteVariables,  jsxStringToReact } from '../JSXParser.js'
import { Function, FunctionContext } from './ui/Function.tsx';    
import { RendererContext } from './RendererContext.tsx'; 
import { YapUIDecoder } from '../YapUIDecoder.js';
import AST from '../YapUIAST.js'
import { AssetsContext } from './ui/Assets.tsx';
import { ChildrenContext } from './ui/Children.tsx';
import { ComponentContext } from './ui/Component.tsx';
import {  Environment } from './ui/Environment.tsx';
import { evalCaptureFunctions } from './Utils.tsx';
import JSON5 from 'json5'


export function Renderer({ yapui, componentName, allowDefaultFrame = true, getAsset, frame, viewCallback, jsCallback, entryPointsCallback, setPreviews, previews, previewIndex, environment }) {

    const [result, setResult] = React.useState(null);

    const parseJSONAST = (ast) => { 
        try {
            return JSON5.parse(ast)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log('Renderer: content changed')
        console.log('Renderer: previews', previews)
        console.log('Renderer: previewIndex', previewIndex)

        // Body AST
        var currentAST = yapui

        // Use Preview AST if available
        if (previews) {   
            let preview = previews[previewIndex ?? 0]
            if (preview && preview.body) {
                currentAST = preview.body
            }
        }
        

        /**
         * If the root node is a component description, extract the component name and previews
         */
        var bodyContent = parseJSONAST(currentAST)
       
        /**
         * Expose web only javascript functions
         */
        let functions = {}
        try {
            let js = jsCallback ? jsCallback("App") : null
            functions = evalCaptureFunctions(js)
        } catch (e) {

        }

        /**
         * Setup a render view callback to handle 'Self' and current component name views
         */
        const renderViewCallback = (name) => {  
            const lowercaseName = name && name.toLowerCase ? name.toLowerCase() : null

            if (functions[name]) {
                let Func = functions[name]  
                return <Func/>;
            } if (name == 'Self' || lowercaseName == (componentName ?? "").toLowerCase()) {
                let finalName = name == 'Self' ? componentName : name
                return <AssetsContext.Provider value={{ get: getAsset, name: finalName }}>
                            {YapUIDecoder(parseJSONAST(yapui), renderViewCallback)}
                        </AssetsContext.Provider>                            
            } else {
                return (
                        <AssetsContext.Provider value={{ get: getAsset, name: name }}>
                            {viewCallback(name)}
                        </AssetsContext.Provider>
                )
            }
        }

        /*
        * Apply frame if provided
        */
        var rootComponentName = componentName
        if (allowDefaultFrame) {
            rootComponentName = frame ?? 'DefaultFrame'
            bodyContent = AST.Directive(rootComponentName, {}, [bodyContent])
        }
        console.log('Renderer: bodyContent', bodyContent)
        const content = YapUIDecoder(bodyContent, renderViewCallback, entryPointsCallback)
        setResult(content)

    }, [yapui, frame, previewIndex, previews, componentName])

    return (
        <RendererContext.Provider value={{ viewCallback: viewCallback }}>
                <ChildrenContext.Provider value={{ children: [] }}>
                    <FunctionContext.Provider value={{ functions: {}, functionArgs: {}, scripts: {} }}>
                        <RendererContainer className="rendererContainer">      
                            <Environment values={environment}>
                                {result}
                            </Environment>
                        </RendererContainer>
                    </FunctionContext.Provider>
                </ChildrenContext.Provider>
        </RendererContext.Provider>
    )
}

const RendererContainer = styled.div`   
    display: flex;
    flex-direction: column;;
    align-items: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    justify-content: center;
    overflow: hidden;
    
    & * {
        box-sizing: border-box;
    }    
`