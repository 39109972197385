import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { Defaults } from "./State.tsx";

export function Environment( { children, values}) {
    let env = { 'environment.platform': 'web' };

    return (
        <Defaults {...env}>
            <EnvironmentScreen>
                <Defaults {...values}>
                {children}
                </Defaults>
            </EnvironmentScreen>
        </Defaults>
    )
}

function EnvironmentScreen({children}) {

    // Define breakpoints for mobile, tablet, and desktop
    const breakpoints: { [key: string]: string } = {
        mobile: '(max-width: 550px)',
        tablet: '(min-width: 551px) and (max-width: 1023px)',
        desktop: '(min-width: 1024px)',
    };

    // Use the custom hook to get the current screen size
    const screenSize = useResponsiveScreen(breakpoints);

    let env = { 'environment.screen': screenSize };

    return (    
        <Defaults {...env}>
            {children}
        </Defaults>
    )
}

// Define the type for the breakpoints object
type Breakpoints = {
    [size: string]: string;
};

// Custom hook to handle responsive breakpoints
function useResponsiveScreen(breakpoints: Breakpoints): string | null {
    const [screenSize, setScreenSize] = useState<string | null>(null);

    useEffect(() => {
        const mediaQueries = Object.keys(breakpoints).map((size) => {
            return {
                size,
                query: window.matchMedia(breakpoints[size]),
            };
        });

        const updateScreenSize = () => {
            const matchingQuery = mediaQueries.find(({ query }) => query.matches);
            if (matchingQuery && matchingQuery.size !== screenSize) {
                setScreenSize(matchingQuery.size);
            }
        };

        // Set initial screen size
        updateScreenSize();

        // Add event listeners to all media queries
        mediaQueries.forEach(({ query }) => {
            query.addEventListener('change', updateScreenSize);
        });

        // Cleanup event listeners when the component unmounts
        return () => {
            mediaQueries.forEach(({ query }) => {
                query.removeEventListener('change', updateScreenSize);
            });
        };
    }, [breakpoints, screenSize]);

    return screenSize;
}